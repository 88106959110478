<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="ballValve" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        [colCount]="4">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
          [label]="{ text: 'TYPE' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="linea_id" editorType="dxSelectBox" [editorOptions]="familyEditorOptions$ | async"
          [label]="{text: 'FAMILY' | translate}" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG FAMILY' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="kv" editorType="dxNumberBox" [label]="{ text: 'KV' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG KV' | translate"></dxi-validation-rule></dxi-item>
        <dxi-item dataField="diametro" editorType="dxNumberBox" [label]="{ text: 'DIAMETER' | translate }"
          [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG DIAMETER' | translate"></dxi-validation-rule></dxi-item>
        <dxi-item dataField="area" editorType="dxNumberBox" [label]="{ text: 'AREA' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG AREA' | translate"></dxi-validation-rule></dxi-item>
        <dxi-item dataField="coefficiente_perdita_pressione" editorType="dxNumberBox"
          [label]="{ text: 'PRESSURE LOSS COEFFICIENT' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required"
            [message]="'MSG PRESSURE LOSS COEFFICIENT' | translate"></dxi-validation-rule></dxi-item>
        <dxi-item dataField="ps" editorType="dxNumberBox" [label]="{ text: 'PS' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="ts_min" editorType="dxNumberBox" [label]="{ text: 'TS MIN' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="ts_max" editorType="dxNumberBox" [label]="{ text: 'TS MAX' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="a_npt" [label]="{ text: 'ANPT' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="b_npt" [label]="{ text: 'BNPT' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="c" editorType="dxNumberBox" [label]="{ text: 'C' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="h1" editorType="dxNumberBox" [label]="{ text: 'H1' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="h2" editorType="dxNumberBox" [label]="{ text: 'H2' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="h3" editorType="dxNumberBox" [label]="{ text: 'H3' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="l1" editorType="dxNumberBox" [label]="{ text: 'L1' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="l2" editorType="dxNumberBox" [label]="{ text: 'L2' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="l3" editorType="dxNumberBox" [label]="{ text: 'L3' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="peso" editorType="dxNumberBox" [label]="{ text: 'WEIGHT' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="coppia_chiave_collegamento_min" editorType="dxNumberBox"
          [label]="{ text: 'INLET CONNECTION WRENCH TORQUE MIN' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="coppia_chiave_collegamento_max" editorType="dxNumberBox"
          [label]="{ text: 'INLET CONNECTION WRENCH TORQUE MAX' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="omologazioni" editorType="dxTagBox" [editorOptions]="{ dataSource: omologazioneTypes }"
          [label]="{ text: 'HOMOLOGATIONS' | translate }" [colSpan]="2"></dxi-item>
        <dxi-item *ngIf="!!ballValve" itemType="group" [colSpan]="4">
          <dxi-item>
            <div *dxTemplate>
              <img *ngIf="!!ballValve.photo_url" class="previewImg"
                [attr.src]="ballValve.photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                  accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>