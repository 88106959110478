import { InterpolationDataRegolo, InterpolationDataRegoloService } from '../interpolation-data-regolo.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { DxService } from 'app/common/dx.service';
import { UtilsService } from 'app/common/utils.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-form-interpolation-data-regolo-popup',
  templateUrl: './form-interpolation-data-regolo-popup.component.html',
  styleUrls: ['./form-interpolation-data-regolo-popup.component.scss']
})
export class FormInterpolationDataRegoloPopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @Input() id: string;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<InterpolationDataRegolo>();
  disposeBag = new DisposeBag();
  isVisible: boolean;
  interpolationData: InterpolationDataRegolo;
  isFormInvalid: boolean;
  isContentReady: boolean;
  popupTitle$: Observable<string>;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;

  constructor(
    private dxService: DxService,
    private utils: UtilsService,
    private interpolationDataService: InterpolationDataRegoloService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.onFormSubmit());
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  onHiding() {
    this.closePopup();
  }

  onShowing() {
    if (this.isNew()) {
      this.interpolationData = new InterpolationDataRegolo();
    } else {
      this.disposeBag.add(this.interpolationDataService.getOne(this.id).subscribe(
        interpolationData => {
          this.interpolationData = interpolationData;
          this.dxForm.instance.updateData(this.interpolationData);
        },
        () => this.closePopup()
      ));
    }
  }

  onShown() {
    this.popupTitle$ = this.isNew() ? this.utils.translate('ADD INTERPOLATION DATA') : this.utils.translate('EDIT INTERPOLATION DATA');
  }

  onContentReady() {
    this.isContentReady = true;
    this.isFormInvalid = !this.isFormValid();
  }

  onFieldDataChanged() {
    if (this.isContentReady) {
      this.isFormInvalid = !this.isFormValid();
    }
  }

  isTemperatureSelected(): boolean {
    return !!this.interpolationData.temperature || !!this.interpolationData.temperatureLiquid || !!this.interpolationData.temperatureVapor;
  }
  
  private onFormSubmit() {
    const data = Object.assign({}, this.interpolationData);
    this.closePopup(data);
  }

  private closePopup(interpolationData?: InterpolationDataRegolo) {
    this.setVisible(false);
    this.afterClosed.emit(interpolationData);
    this.isFormInvalid = true;
    this.popupTitle$ = null;
    this.interpolationData = null;
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isTemperatureSelected();
  }

  private isNew(): boolean {
    return !this.id;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
