import { Adattatore } from './../adattatore/adattatore.service';
import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';
import { Attuatore } from '../attuatore/attuatore.service';

@Injectable({
  providedIn: 'root'
})
export class RubinettoService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'rubinetto';
  }

  create(ballValve: Rubinetto, file: any): Observable<any> {
    const formData = this.setFormData(ballValve, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, ballValve: Rubinetto, file: any): Observable<any> {
    const formData = this.setFormData(ballValve, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(ballValve: Rubinetto, file: any): FormData {
    ballValve.descrizione = JSON.stringify(ballValve.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(ballValve));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Rubinetto {
  id: number;
  numero_catalogo: string;
  attacco_ods_inch: string;
  attacco_ods_mm: number;
  attacco_w_mm: number;
  foro_sfera_mm: number;
  kv: number;
  ps: number;
  ts_min: number;
  ts_max: number;
  coppia: number;
  tempo_marcia: number;
  potenza: number;
  numero_pezzi: number;
  tipo: string;
  descrizione: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;
  linea_id: number;
  famiglia: any;
  attuatori: Attuatore[];
  adattatori: Adattatore[];

  constructor() {
    this.attacco_ods_mm = null;
    this.attacco_w_mm = null;
    this.foro_sfera_mm = null;
    this.kv = null;
    this.ps = null;
    this.ts_min = null;
    this.ts_max = null;
    this.coppia = null;
    this.tempo_marcia = null;
    this.potenza = null;
    this.numero_pezzi = null;
  }
}

export enum RubinettoTypes {
  DUE_VIE = '2 vie',
  TRE_VIE_MANUALE = '3 vie manuale',
  TRE_VIE_MOTORIZZATO = '3 vie motorizzato'
}
