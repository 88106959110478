import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sonda, SondaService } from '../sonda.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'ms-form-sonda',
  templateUrl: './form-sonda.component.html',
  styleUrls: ['./form-sonda.component.scss']
})
export class FormSondaComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  probe: Sonda;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isNameValid: boolean;
  isNotesValid: boolean;
  isDescriptionValid: boolean;
  id: any;
  photo: any;
  types: string[];

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private sondaService: SondaService,
    private configService: ConfigService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isNameValid = false;
    this.isNotesValid = false;
    this.isDescriptionValid = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('PROBES');
    this.types = this.configService.getSondaTypes();
    this.disposeBag.add(this.getProbe().subscribe(probe => {
      if (!probe.nome) { probe.nome = '{}'; }
      if (!probe.note) { probe.note = '{}'; }
      if (!probe.descrizione) { probe.descrizione = '{}'; }
      this.probe = probe;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.probe); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) { this.isFormInvalid = !this.isFormValid(); }
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.sondaService.create(this.probe, this.photo) : this.sondaService.update(this.id, this.probe, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['probes']);
  }

  private getProbe(): Observable<Sonda> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT PROBE';
        return this.sondaService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD PROBE';
        return of(new Sonda());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isNameValid && this.isNotesValid && this.isDescriptionValid;;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
