import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndicatoreService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'indicatore';
  }

  create(indicator: Indicatore, file: any): Observable<any> {
    const formData = this.setFormData(indicator, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, indicator: Indicatore, file: any): Observable<any> {
    const formData = this.setFormData(indicator, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(indicator: Indicatore, file: any): FormData {
    indicator.descrizione = JSON.stringify(indicator.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(indicator));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Indicatore {
  id: number;
  numero_catalogo: string;
  attacco_sae: string;
  attacco_ods_inch: string;
  attacco_ods_mm: number;
  attacco_w_mm: number;
  kv: number;
  ps: number;
  ts_min: number;
  ts_max: number;
  linea: string;
  descrizione: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;
  famiglia: any[];
  family?: string;

  constructor() {
    this.attacco_ods_mm = null;
    this.attacco_w_mm = null;
    this.kv = null;
    this.ps = null;
    this.ts_min = null;
    this.ts_max = null;
  }
}

export enum IndicatoreLineaTypes {
  LIQUIDO = 'Liquido',
  UMIDITA = 'Umidità'
}
