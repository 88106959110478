import { AppApiService } from 'app/common/app-api.service';
import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { ConfigService } from 'app/common/config.service';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { LoadingService } from 'app/common/loading.service';
import { DxService } from 'app/common/dx.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { SolenoideBobinaMOPD } from '../../components/solenoide/solenoide.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-grid-mopd',
  templateUrl: './grid-mopd.component.html',
  styleUrls: ['./grid-mopd.component.scss']
})
export class GridMopdComponent extends GridBaseComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  @Output() modelChange = new EventEmitter<SolenoideBobinaMOPD[]>();
  mopdList: SolenoideBobinaMOPD[];
  currentTypes: string[];
  coilSeries$: Observable<any>;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private appApiService: AppApiService
  ) {
    super(configService, dxService, loadingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.currentTypes = this.configService.getCorrenteTypes();
    this.coilSeries$ = this.appApiService.getCoilSeries();
  }

  addMOPD() {
    this.dxDataGrid.instance.addRow();
  }

  @Input()
  get model() {
    return this.mopdList;
  }

  set model(val: SolenoideBobinaMOPD[]) {
    this.setModel(val);
  }

  setModel(val: SolenoideBobinaMOPD[]) {
    this.mopdList = val;
    this.modelChange.emit(this.mopdList);
  }
}