import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';
import { Adattatore } from '../adattatore/adattatore.service';

@Injectable({
  providedIn: 'root'
})
export class AttuatoreService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'attuatore';
  }

  create(actuator: Attuatore, file: any): Observable<any> {
    const formData = this.setFormData(actuator, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, actuator: Attuatore, file: any): Observable<any> {
    const formData = this.setFormData(actuator, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(actuator: Attuatore, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(actuator));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Attuatore {
  id: number;
  numero_catalogo: string;
  tensione: number;
  coppia: number;
  contatto_ausiliare: string;
  tempo_marcia: number;
  potenza: number;
  grado_protezione: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;
  adattatori: Adattatore[];
}

export enum AttuatoreTensioneTypes {
  VENTIQUATTRO = 24,
  DUECENTOTRENTA = 230
}