import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnettoreService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'connettore';
  }

  create(connector: Connettore, file: any): Observable<any> {
    const formData = this.setFormData(connector, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, connector: Connettore, file: any): Observable<any> {
    const formData = this.setFormData(connector, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(connector: Connettore, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(connector));
    if (!!file) {
      formData.append('file', file);
    }
    return formData;
  }
}

export class Connettore {
  id: number;
  numero_catalogo: string;
  tensione_nominale: string;
  tensione_massima: string;
  lunghezza_cavo: number;
  sezione_cavo: string;
  standard: string;
  grado_protezione: string;
  classe_isolamento: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;
}
