import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { Observable, zip } from 'rxjs';
import { FormPasswordComponent } from 'app/components/user/form-password/form-password.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  profileDialogRef: MatDialogRef<FormPasswordComponent>;

  constructor(private translateService: TranslateService, private pageTitleService: PageTitleService, private dialog: MatDialog) { }

  translate(key: string): Observable<string> {
    return this.translateService.get(key);
  }

  translateArray(array: string[]): Observable<string[]> {
    return zip(...array.map(type => this.translate(type)));
  }

  translateArrayDict(keys: string[]): Observable<any[]> {
    return this.translateArray(keys).map(array => {
      return array.map((item, i) => {
        return { id: keys[i], text: item };
      });
    });
  }

  translateArrayDictWithIds(keys: string[]): Observable<any[]> {
    return this.translateArray(keys).map(array => {
      return array.map((item, i) => {
        return { id: i, text: item };
      });
    });
  }

  translateDict(keys: string[]): Observable<any> {
    return this.translateArrayDict(keys).map(res => {
      const dict = {};
      for (const el of res) {
        dict[el.id] = el.text;
      }
      return dict;
    });
  }

  arrayToDict(array: any[], primaryKey: any, valueKey: string = null): any {
    const dict = {};
    for (const el of array) {
      dict[el[primaryKey]] = !!valueKey && valueKey !== '' ? el[valueKey] : el;
    }
    return dict;
  }

  objectArrayToDict(array: any[], primaryKey: any): any {
    const dict = {};
    for (const el of array) {
      dict[el[primaryKey]] = el;
    }
    return dict;
  }

  translateMultiLanguageField(data: string): string {
    const obj = JSON.parse(data);
    return obj[this.translateService.currentLang] || obj['en'];
  }

  setPageTitleByTranslateKey(key: string) {
    this.translateService.get(key).subscribe((translatedText: string) => this.pageTitleService.setTitle(translatedText));
  }

  async dataURLToFile(url: string, fileName: string): Promise<File> {
    return this.dataURLToBlob(url).then(blob => this.blobToFile(blob, fileName));
  }

  openPasswordDialog(id: any): Observable<any> {
    this.profileDialogRef = this.dialog.open(FormPasswordComponent, {
      panelClass: 'form-password',
      disableClose: false,
      data: id,
      width: '50%',
      height: '50%'
    });
    return this.profileDialogRef.afterClosed().map(result => {
      this.profileDialogRef = null;
      return result;
    });
  }

  private async dataURLToBlob(url: RequestInfo): Promise<Blob> {
    return fetch(url).then(res => res.blob());
  }

  private blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>theBlob;
  }
}
