import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortataService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'portata';
  }

  getBySicurezzaId(safetyValveId: number): Observable<Portata[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, 'sicurezza', safetyValveId.toString()));
  }

  importFromCsv(safetyValveId: number, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.requestService.multipartRequestWithFormData(
      'PUT',
      this.apiService.createUrlFromBaseUrl(this.target, 'sicurezza', safetyValveId.toString(), 'csv'),
      formData,
      true
    );
  }
}

export class Portata {
  id: number;
  refrigerante_id: number;
  sicurezza_id: number;
  portata: number;
  volume: number;
  pressione: number;
  temperatura: number;
  coefficiente: number;
  tipo: string;
}

export enum PortataTypes {
  EN_13136 = 'EN 13136',
  ISO_DIS_24664 = 'ISO-DIS 24664'
}
