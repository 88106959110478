import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FParameterService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'f_parameter';
  }

  getByRefrigerantId(refrigerantId: number): Observable<FParameter[]> {
    return this.requestService.get(
      this.apiService.createUrlFromBaseUrl(this.target, 'refrigerante', refrigerantId.toString())
    );
  }
}

export class FParameter {
  id: number;
  refrigerante_id: number;
  valore: number;
  pressione: number;
}
