import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { DxService } from '../dx.service';

@Component({
  selector: 'ms-form-csv-popup',
  templateUrl: './form-csv-popup.component.html',
  styleUrls: ['./form-csv-popup.component.scss']
})
export class FormCsvPopupComponent implements OnInit {
  @ViewChild(DxFileUploaderComponent, { static: false }) uploader: DxFileUploaderComponent;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<File>();
  @Input() text: string;
  csvFile: File;
  isVisible: boolean;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;

  constructor(private dxService: DxService) { }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.closePopup(this.csvFile));
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  onHiding() {
    this.closePopup();
  }

  fileChange(event: File[]) {
    this.csvFile = null;
    if (event.length > 0) {
      this.csvFile = event[0];
    }
  }

  private closePopup(file?: File) {
    this.setVisible(false);
    this.afterClosed.emit(file);
    this.uploader.instance.reset();
    this.csvFile = null;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
