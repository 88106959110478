import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialeService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'materiale';
  }
}

export class Materiale {
  id: number;
  nome: string;
  scabrezza: number;

  constructor() {
    this.scabrezza = null;
  }
}
