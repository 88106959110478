<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="coil" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        colCount="2">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="serie" [label]="{ text: 'SERIES' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG SERIES' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo" [label]="{ text: 'TYPE' | translate }" colSpan="1"></dxi-item>
        <dxi-item dataField="tensione" [label]="{ text: 'VOLTAGE' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG VOLTAGE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo_corrente" editorType="dxSelectBox" [editorOptions]="{ dataSource: currentTypes }"
          [label]="{ text: 'CURRENT TYPE' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG CURRENT TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tolleranza_tensione" [label]="{ text: 'VOLTAGE TOLERANCE' | translate }" colSpan="1">
        </dxi-item>
        <dxi-item dataField="frequenza" [label]="{ text: 'FREQUENCY' | translate }" colSpan="1"></dxi-item>
        <dxi-item dataField="classe_isolamento" [label]="{ text: 'INSULATION CLASS' | translate }" colSpan="1">
        </dxi-item>
        <dxi-item dataField="collegamento" [label]="{ text: 'CONNECTION' | translate }" colSpan="1">
          <dxi-validation-rule type="stringLength" max="20" message="{{ 'MSG MAX STRING LENGTH' | translate }} 20">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="grado_protezione" [label]="{ text: 'PROTECTION LEVEL' | translate }" colSpan="1">
        </dxi-item>
        <dxi-item dataField="ta_min" editorType="dxNumberBox" [editorOptions]="{ value: null }"
          [label]="{ text: 'TA MIN' | translate }" colSpan="1">
        </dxi-item>
        <dxi-item dataField="ta_max" editorType="dxNumberBox" [editorOptions]="{ value: null }"
          [label]="{ text: 'TA MAX' | translate }" colSpan="1">
        </dxi-item>
        <dxi-item dataField="potenza" editorType="dxNumberBox" [label]="{ text: 'POWER' | translate }" colSpan="1">
        </dxi-item>
        <dxi-item dataField="omologazioni" editorType="dxTagBox" [editorOptions]="{ dataSource: omologazioneTypes }"
          [label]="{ text: 'HOMOLOGATIONS' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="connettori" editorType="dxTagBox" [editorOptions]="connectorsEditorOptions$ | async"
          [label]="{ text: 'CONNECTORS' | translate }" colSpan="2"></dxi-item>
        <dxi-item colSpan="2">
          <div *dxTemplate>
            <img *ngIf="!!coil && !!coil.photo_url" class="previewImg"
              [attr.src]="coil.photo_url | blobImageUrl | async" />
            <div class="fileuploader-container">
              <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid">
          </dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>