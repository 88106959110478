import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CapacitaDisidratante, CapacitaDisidratanteService } from '../capacita-disidratante.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-grid-capacita-disidratante',
  templateUrl: './grid-capacita-disidratante.component.html',
  styleUrls: ['./grid-capacita-disidratante.component.scss']
})
export class GridCapacitaDisidratanteComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  @Input() filterId: number;
  @Input() coreId: number;
  disposeBag = new DisposeBag();
  dehydratingCapacities$: Observable<CapacitaDisidratante[]>;
  id: any;
  popupVisible: boolean;
  filePopupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private capacitaDisidratanteService: CapacitaDisidratanteService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
    this.filePopupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.handleRequest(this.deleteWithConfirm(this.capacitaDisidratanteService.delete(event.row.data.id)));
  }

  afterClosed(event: CapacitaDisidratante) {
    if (!!event) {
      event.filtro_id = this.filterId || null;
      event.cartuccia_id = this.coreId || null;
      const observable$ = this.isNew()
        ? this.capacitaDisidratanteService.insert(event) : this.capacitaDisidratanteService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  afterFileClosed(event: File) {
    if (!!event) {
      const observable$ = !!this.filterId ? this.capacitaDisidratanteService.importFromCsvFiltro(this.filterId, event)
        : this.capacitaDisidratanteService.importFromCsvCartuccia(this.coreId, event);
      this.handleRequest(this.withConfirm(observable$, 'IMPORT CSV ALERT MSG'));
    }
  }

  openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  openFilePopup() {
    this.filePopupVisible = true;
  }

  private loadData() {
    this.dehydratingCapacities$ = !!this.filterId ? this.capacitaDisidratanteService.getByFiltroId(this.filterId)
      : this.capacitaDisidratanteService.getByCartucciaId(this.coreId);
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(observable$.subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
