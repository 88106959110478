<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="connector" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        [colCount]="2">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="standard" [label]="{ text: 'STANDARD' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="tensione_nominale" [label]="{ text: 'RATED VOLTAGE' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="tensione_massima" [label]="{ text: 'MAX VOLTAGE' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="lunghezza_cavo" editorType="dxNumberBox" [editorOptions]="{ value: null }"
          [label]="{ text: 'CABLE LENGTH' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="sezione_cavo" [label]="{ text: 'CABLE SECTION' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="grado_protezione" [label]="{ text: 'PROTECTION LEVEL' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="classe_isolamento" [label]="{ text: 'INSULATION CLASS' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="omologazioni" editorType="dxTagBox" [editorOptions]="{ dataSource: omologazioneTypes }"
        [label]="{ text: 'HOMOLOGATIONS' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item [colSpan]="2">
          <div *dxTemplate>
            <img *ngIf="!!connector && !!connector.photo_url" class="previewImg"
              [attr.src]="connector.photo_url | blobImageUrl | async" />
            <div class="fileuploader-container">
              <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid">
          </dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>