import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CapacitaFiltro, CapacitaFiltroService } from '../capacita-filtro.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { DxService } from 'app/common/dx.service';
import { UtilsService } from 'app/common/utils.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { RefrigeranteService } from 'app/components/refrigerante/refrigerante.service';

@Component({
  selector: 'ms-form-capacita-filtro-popup',
  templateUrl: './form-capacita-filtro-popup.component.html',
  styleUrls: ['./form-capacita-filtro-popup.component.scss']
})
export class FormCapacitaFiltroPopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @Input() id: string;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<CapacitaFiltro>();
  disposeBag = new DisposeBag();
  isVisible: boolean;
  filterCapacity: CapacitaFiltro;
  isFormInvalid: boolean;
  isContentReady: boolean;
  popupTitle$: Observable<string>;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;
  refrigerantEditorOptions$: Observable<any>;

  constructor(
    private dxService: DxService,
    private utils: UtilsService,
    private capacitaFiltroService: CapacitaFiltroService,
    private refrigeranteService: RefrigeranteService,
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.onFormSubmit());
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  onHiding() {
    this.closePopup();
  }

  onShowing() {
    this.refrigerantEditorOptions$ = this.dxService.dxiItemEditorOptions(this.refrigeranteService.getList(), 'name', 'id', !this.isNew());
    if (this.isNew()) {
      this.filterCapacity = new CapacitaFiltro();
    } else {
      this.disposeBag.add(this.capacitaFiltroService.getOne(this.id).subscribe(
        filterCapacity => {
          this.filterCapacity = filterCapacity;
          this.dxForm.instance.updateData(this.filterCapacity);
        },
        () => this.closePopup()
      ));
    }
  }

  onShown() {
    this.popupTitle$ =
      this.isNew() ? this.utils.translate('ADD FILTER CAPACITY') : this.utils.translate('EDIT FILTER CAPACITY');
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) { this.isFormInvalid = !this.isFormValid(); }
  }

  private onFormSubmit() {
    const data = Object.assign({}, this.filterCapacity);
    this.closePopup(data);
  }

  private closePopup(filterCapacity?: CapacitaFiltro) {
    this.setVisible(false);
    this.afterClosed.emit(filterCapacity);
    this.isFormInvalid = true;
    this.popupTitle$ = null;
    this.filterCapacity = null;
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
