import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User, UserService, RolesTypes } from '../user.service';
import { UtilsService } from '../../../common/utils.service';
import { Observable } from 'rxjs';
import { DisposeBag } from '../../../classes/dispose-bag.class';
import { LoadingService } from '../../../common/loading.service';
import { AuthenticationService, CurrentUser } from 'app/authentication/authentication.service';

const regEx = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

@Component({
  selector: 'ms-form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})
export class FormUserComponent implements OnInit, OnDestroy {
  disposeBag = new DisposeBag();
  form: FormGroup;
  cardTitle: string;
  id: any;
  user: User;
  currentUser: CurrentUser;
  page: number;

  constructor(
    public loadingService: LoadingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private utils: UtilsService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('USERS');
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      surname: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      phone: [null],
      mobile: [null],
      enabled: [null, Validators.compose([Validators.required])],
      company: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.pattern(regEx)])],
      confirm_password: [null, Validators.compose([Validators.pattern(regEx)])]
    }, {
      validator: this.checkPassword
    });
    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.isNew()) {
        this.cardTitle = 'ADD USER';
        this.form.controls.enabled.setValue(false);
      } else {
        this.cardTitle = 'UPDATE USER';
        this.loadUserData();
      }
    });
    this.route.queryParams.subscribe(params => this.page = params['page'] || 0);
    this.currentUser = this.authService.getUser();
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onSubmit() {
    const user = this.formToObj() as any;
    this.isNew() ? this.handleRequest(this.userService.insert(user)) : this.handleRequest(this.userService.put(user, this.id));
  }

  setPassword() {
    this.utils.openPasswordDialog(this.id);
  }

  checkPassword(form: FormGroup) {
    return form.controls.password.value === form.controls.confirm_password.value ? null : { 'mismatch': true };
  }

  validatePassword() {
    return (this.form.controls.password.hasError('required') || this.form.controls.password.hasError('pattern'))
      && this.form.controls.password.touched;
  }

  matchPassword() {
    return !this.form.controls.password.hasError('pattern')
      && this.form.controls.password.value !== this.form.controls.confirm_password.value && this.form.controls.confirm_password.touched;
  }

  isNew(): Boolean {
    return !this.id;
  }

  isMe(): boolean {
    if (!this.user || !this.currentUser) {
      return false;
    }
    return this.user.id === this.currentUser.id;
  }

  goToListPage() {
    this.router.navigate(['users'], { queryParams: { page: this.page } });
  }

  private loadUserData() {
    const id = this.id;
    this.userService.getOne(id).subscribe(res => {
      this.user = res as User;
      this.objToForm(this.user);
    }, err => console.log('Error occured : ' + err)
    );
  }

  private objToForm(user: User) {
    this.form.controls.name.setValue(user.name);
    this.form.controls.surname.setValue(user.surname);
    this.form.controls.email.setValue(user.email);
    this.form.controls.phone.setValue(user.phone);
    this.form.controls.mobile.setValue(user.mobile);
    this.form.controls.enabled.setValue(user.enabled);
    this.form.controls.company.setValue(user.company);
  }

  private formToObj(): any {
    let obj = {};
    if (this.user) {
      obj = this.user;
    }
    obj['name'] = this.form.controls.name.value;
    obj['surname'] = this.form.controls.surname.value;
    obj['email'] = this.form.controls.email.value;
    obj['phone'] = this.form.controls.phone.value;
    obj['mobile'] = this.form.controls.mobile.value;
    obj['enabled'] = this.form.controls.enabled.value;
    obj['company'] = this.form.controls.company.value;
    obj['password'] = this.form.controls.password.value;
    obj['role'] = RolesTypes.ADMIN;
    return obj as any;
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.goToListPage()));
  }
}
