import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Filtro, FiltroFamilies, FiltroService, FiltroTypes } from '../filtro.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DxFormComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { LoadingService } from 'app/common/loading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CartucciaService } from 'app/components/cartuccia/cartuccia.service';
import { DxService } from 'app/common/dx.service';
import { AppApiService } from 'app/common/app-api.service';
import { ConfigService } from 'app/common/config.service';

@Component({
  selector: 'ms-form-filtro',
  templateUrl: './form-filtro.component.html',
  styleUrls: ['./form-filtro.component.scss']
})
export class FormFiltroComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  filter: Filtro;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isDescriptionValid: boolean;
  id: any;
  photo: any;
  families: FiltroFamilies[];
  types: FiltroTypes[];
  omologazioneTypes: string[];
  lineEditorOptions$: Observable<any>;
  coresEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private dxService: DxService,
    private filtroService: FiltroService,
    private appApiService: AppApiService,
    private cartucciaService: CartucciaService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isDescriptionValid = false;
    this.families = Object.values(FiltroFamilies);
    this.types = Object.values(FiltroTypes);
  }

  ngOnInit() {
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.lineEditorOptions$ = this.dxService.dxiItemEditorOptions(this.appApiService.getFamilies(), 'value', 'id');
    this.coresEditorOptions$ = this.dxService.dxiItemEditorOptions(this.cartucciaService.getList(), 'numero_catalogo', 'this');
    this.disposeBag.add(this.getFilter().subscribe(filter => {
      if (!filter.descrizione) { filter.descrizione = '{}'; }
      this.filter = filter;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.filter); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.filtroService.create(this.filter, this.photo) : this.filtroService.update(this.id, this.filter, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['filters']);
  }

  private getFilter(): Observable<Filtro> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT FILTER';
        return this.filtroService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD FILTER';
        return of(new Filtro());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) { return false; }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isDescriptionValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
