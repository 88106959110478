import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable, zip } from 'rxjs';
import { Router } from '@angular/router';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { UserService } from '../user.service';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { DxService } from 'app/common/dx.service';

@Component({
  selector: 'ms-grid-user',
  templateUrl: './grid-user.component.html',
  styleUrls: ['./grid-user.component.scss']
})
export class GridUserComponent extends GridBaseComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  users$: Observable<any[]>;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private router: Router,
    private userService: UserService
  ) {
    super(configService, dxService, loadingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.linkButtonClick = this.linkButtonClick.bind(this);
  }

  linkButtonClick(event: any) {
    this.router.navigate(['users', event.row.data.id]);
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.router.navigate(['users/add'])
      }
    });
  }

  private loadData() {
    this.users$ = this.loadingService.apiRequest$WithLoading(
      zip(this.userService.getList(), this.configService.translatedRoleDict())
    ).map(([users, roles]) => {
      return users.map((user: any) => {
        user.role = user.role.toUpperCase();
        user.role = roles[user.role];
        return user;
      });
    });
  }
}
