<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="expansionValve" (onFieldDataChanged)="onFieldDataChanged()"
        (onContentReady)="onContentReady()" [colCount]="4">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="principi_funzionamento" [label]="{ text: 'WORKING PRINCIPLE' | translate }" [colSpan]="2">
        </dxi-item>
        <dxi-item dataField="attacco_ods_in_inch" [label]="{ text: 'ODS CONNECTION IN INCH' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_ods_out_inch" [label]="{ text: 'ODS CONNECTION OUT INCH' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_ods_in_mm" editorType="dxNumberBox"
          [label]="{ text: 'ODS CONNECTION IN MM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_ods_out_mm" editorType="dxNumberBox"
          [label]="{ text: 'ODS CONNECTION OUT MM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="porta_equivalente" editorType="dxNumberBox"
          [label]="{ text: 'EQUIVALENT PORT' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="tipo_orifizio" editorType="dxNumberBox" [label]="{ text: 'ORIFICE TYPE' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="kv" editorType="dxNumberBox" [label]="{ text: 'KV' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="min_opd" editorType="dxNumberBox" [label]="{ text: 'MIN OPD' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="m_opd" editorType="dxNumberBox" [label]="{ text: 'MOPD' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ps" editorType="dxNumberBox" [label]="{ text: 'PS' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="ts_min" editorType="dxNumberBox" [label]="{ text: 'TS MIN' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ts_max" editorType="dxNumberBox" [label]="{ text: 'TS MAX' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ta_min" editorType="dxNumberBox" [label]="{ text: 'TA MIN' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ta_max" editorType="dxNumberBox" [label]="{ text: 'TA MAX' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="tempo_minimo_intervento" editorType="dxNumberBox"
          [label]="{ text: 'MINIMUM WORKING TIME' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="dimensione_corpo" editorType="dxNumberBox" [label]="{ text: 'BODY SIZE' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="categoria_rischio" [label]="{ text: 'RISK CATEGORY' | translate }" [colSpan]="2">
        </dxi-item>
        <dxi-item dataField="linea_id" editorType="dxSelectBox" [editorOptions]="familyEditorOptions$ | async"
          [label]="{text: 'FAMILY' | translate}" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG FAMILY' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
          [label]="{text: 'TYPE' | translate}" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="omologazioni" editorType="dxTagBox" [editorOptions]="{ dataSource: omologazioneTypes }"
          [label]="{ text: 'HOMOLOGATIONS' | translate }" [colSpan]="2"></dxi-item>
        <dxi-item dataField="connettori" editorType="dxTagBox" [editorOptions]="connectorsEditorOptions$ | async"
          [label]="{ text: 'CONNECTORS' | translate }" [colSpan]="2"></dxi-item>
        <dxi-item *ngIf="!!expansionValve" itemType="group" [colSpan]="4">
          <dxi-item [label]="{ text: 'DESCRIPTION' | translate }">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="expansionValve.descrizione" [(isValid)]="isDescriptionValid"
                [isRequired]="false" (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <img *ngIf="!!expansionValve.photo_url" class="previewImg"
                [attr.src]="expansionValve.photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                  accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
          <dxi-item *ngIf="!!expansionValve.espansione_bobina_MOPD" [label]="{text: 'MOPD COIL' | translate}">
            <div *dxTemplate>
              <ms-grid-mopd [(model)]="expansionValve.espansione_bobina_MOPD" (modelChange)="onFieldDataChanged()">
              </ms-grid-mopd>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>