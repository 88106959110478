<dx-popup [maxWidth]="600" [maxHeight]="600" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="adapter" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()">
      <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }">
        <dxi-validation-rule type="required" message="{{ 'MSG CATALOGUE NUMBER' | translate }}"></dxi-validation-rule>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <img *ngIf="!!adapter && !!adapter.photo_url" class="previewImg"
            [attr.src]="adapter.photo_url | blobImageUrl | async" />
          <div class="fileuploader-container">
            <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
              accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
            </dx-file-uploader>
          </div>
        </div>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>