import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { BaseInterceptor } from 'app/classes/base-interceptor';
import { Observable } from 'rxjs';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class TokenInterceptor extends BaseInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService) {
    super(auth);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthenticationToken(request));
  }
}
