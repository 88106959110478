import { EspansioneTypes } from './../components/espansione/espansione.service';
import { RubinettoTypes } from './../components/rubinetto/rubinetto.service';
import { RitegnoLineaTypes } from './../components/ritegno/ritegno.service';
import { StatoTypes } from './../components/solenoide/solenoide.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { RolesTypes } from 'app/components/user/user.service';
import { DisegnoTypes } from 'app/components/ritegno/ritegno.service';
import { CorrenteTypes } from 'app/components/bobina/bobina.service';
import { IndicatoreLineaTypes } from 'app/components/indicatore/indicatore.service';
import { AttuatoreTensioneTypes } from 'app/components/attuatore/attuatore.service';
import { ApplicazioneTypes, AlimentazioneTypes } from 'app/components/quadro/quadro.service';
import { SondaTypes } from 'app/components/sonda/sonda.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private utils: UtilsService) { }

  translatedRoleDict(): Observable<any> {
    return this.utils.translateDict(this.getRoleTypes());
  }

  getStatoTypes(): string[] {
    return [StatoTypes.NA, StatoTypes.NC];
  }

  getRitegnoLineaTypes(): string[] {
    return [RitegnoLineaTypes.ERMETICA, RitegnoLineaTypes.CO2];
  }

  getDisegnoTypes(): string[] {
    return [DisegnoTypes.LINEA, DisegnoTypes.SQUADRA];
  }

  getCorrenteTypes(): string[] {
    return [CorrenteTypes.AC, CorrenteTypes.DC, CorrenteTypes.RAC];
  }

  getIndicatoreLineaTypes(): string[] {
    return [IndicatoreLineaTypes.LIQUIDO, IndicatoreLineaTypes.UMIDITA];
  }

  getRubinettoTypes(): string[] {
    return [RubinettoTypes.DUE_VIE, RubinettoTypes.TRE_VIE_MANUALE, RubinettoTypes.TRE_VIE_MOTORIZZATO];
  }

  getEspansioneTypes(): string[] {
    return [EspansioneTypes.PULSE, EspansioneTypes.STEPPER];
  }

  getApplicazioneTypes(): string[] {
    return [ApplicazioneTypes.CONTROLLO_VALVOLA, ApplicazioneTypes.UNITA_EVAPORANTE, ApplicazioneTypes.COMPLETO_CELLA];
  }

  getAlimentazioneTypes(): string[] {
    return [AlimentazioneTypes.MONOFASE, AlimentazioneTypes.TRIFASE];
  }

  getAttuatoreTensioneTypes(): number[] {
    return [AttuatoreTensioneTypes.VENTIQUATTRO, AttuatoreTensioneTypes.DUECENTOTRENTA];
  }

  getOmologazioneTypes(): string[] {
    return [OmologazioneTypes.CE, OmologazioneTypes.UL, OmologazioneTypes.UKCA, OmologazioneTypes.EAC];
  }

  getSondaTypes(): string[] {
    return [SondaTypes.PRESSIONE, SondaTypes.TEMPERATURA];
  }

  getLanguages(): any[] {
    return [
      { key: 'it', label: 'ITALIAN' },
      { key: 'en', label: 'ENGLISH' },
      { key: 'es', label: 'SPANISH' },
      { key: 'de', label: 'GERMAN' },
      { key: 'fr', label: 'FRENCH' },
      { key: 'ro', label: 'ROMANIAN' },
      { key: 'zh', label: 'CHINESE' }
    ];
  }

  getRefrigerantTabItems(): string[] {
    return [
      'FIRST PARAMETERS',
      'THIRD PARAMETERS',
      'FILTER PARAMETERS',
      'REFRIGERATION CAPACITIES',
      'INTERPOLATION DATA',
      'F PARAMETERS',
      'VAPORIZATION CAPACITIES'
    ];
  }

  getFilterTabItems(): string[] {
    return ['FILTER CAPACITIES', 'DEHYDRATING CAPACITIES'];
  }

  getSafetyValveTabItems(): string[] {
    return ['FLOW RATES', 'DISCHARGE CAPACITIES'];
  }

  getShortTextWidth(): string {
    return '250';
  }

  getTimestampWidth(): string {
    return '150';
  }

  getNumericWidth(): string {
    return '100';
  }

  getElapsedTimeWidth(): string {
    return '150';
  }

  getUserFullNameWidth(): string {
    return '200';
  }

  getButtonWidth(): string {
    return '110';
  }

  getImageWidth(): string {
    return '150';
  }

  private getRoleDict(): any[] {
    return [{ id: RolesTypes.ADMIN, text: 'ADMIN' }, { id: RolesTypes.USER, text: 'USER' }];
  }

  private getRoleTypes(): string[] {
    return this.getRoleDict().map(role => {
      return role.text
    });
  }
}

export enum OmologazioneTypes {
  CE = 'CE',
  UL = 'UL',
  UKCA = 'UKCA',
  EAC = 'EAC'
}
