import { ConnettoreService } from './../connettore.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Connettore } from '../connettore.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';
import { ConfigService } from 'app/common/config.service';

@Component({
  selector: 'ms-form-connettore',
  templateUrl: './form-connettore.component.html',
  styleUrls: ['./form-connettore.component.scss']
})
export class FormConnettoreComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  connector: Connettore;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  id: any;
  photo: any;
  omologazioneTypes: string[];

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private configService: ConfigService,
    private connettoreService: ConnettoreService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('CONNECTORS');
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.disposeBag.add(this.getConnector().subscribe(connector => {
      this.connector = connector;
      if (!this.isNew()) {
        this.dxForm.instance.updateData(this.connector);
      }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) {
      this.isFormInvalid = !this.isFormValid();
    }
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.connettoreService.create(this.connector, this.photo) : this.connettoreService.update(this.id, this.connector, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) {
      this.photo = event[0];
    }
  }

  goToListPage() {
    this.router.navigate(['connectors']);
  }

  private getConnector(): Observable<Connettore> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT CONNECTOR';
        return this.connettoreService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD CONNECTOR';
        return of(new Connettore());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
