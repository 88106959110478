import { LogService } from './../common/log.service';
import { UtilsService } from 'app/common/utils.service';
import { LoadingService } from './../common/loading.service';
import { DxService } from './../common/dx.service';
import { AppApiService } from './../common/app-api.service';
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CoreService } from '../core/core.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';

@Component({
  selector: 'gene-layout',
  templateUrl: './main-material.html',
  styleUrls: ['./main-material.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class MainComponent implements OnInit, OnDestroy {
  layout = 'ltr';
  currentLang: any = 'it';
  customizerIn = false;
  sidenavOpen = true;
  isMobile = false;
  collapseSidebarStatus: boolean;
  header: string;
  dark: boolean;
  compactSidebar: boolean;
  isMobileStatus: boolean;
  sidenavMode = 'side';
  url: string;
  windowSize: number;
  _router: Subscription;
  disposeBag = new DisposeBag();
  @ViewChild('sidenav', { static: true }) sidenav: any;

  constructor(
    public translate: TranslateService,
    public coreService: CoreService,
    public loadingService: LoadingService,
    private router: Router,
    private pageTitleService: PageTitleService,
    private appApiService: AppApiService,
    private dxService: DxService,
    private utilsService: UtilsService,
    private logger: LogService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
  }

  ngOnInit() {
    this.coreService.collapseSidebarStatus = this.coreService.collapseSidebar;
    this.pageTitleService.title.subscribe((val: string) => {
      this.header = val;
    });
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.coreService.collapseSidebarStatus = this.coreService.collapseSidebar;
      this.url = event.url;
      this.customizeSidebar();
    });
    this.url = this.router.url;
    this.customizeSidebar();
    setTimeout(() => {
      this.windowSize = window.innerWidth;
      this.resizeSideBar();
    }, 0)
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this.disposeBag.dispose();
  }

  publishSelectorData() {
    this.handleRequest(this.appApiService.publishSelectorData());
  }

  publishRulerData() {
    this.handleRequest(this.appApiService.publishRulerData());
  }

  onActivate(scrollContainer: any) {
    scrollContainer.scrollTop = 0;
  }

  toggleSidebar() {
    this.coreService.sidenavOpen = !this.coreService.sidenavOpen;
  }

  collapseSidebar() {
    document.getElementById('main-app').classList.toggle('collapsed-sidebar');
  }

  onResize(event: any) {
    this.windowSize = event.target.innerWidth;
    this.resizeSideBar();
  }

  customizeSidebar() {
    if (window.innerWidth > 1200) {
      this.coreService.sidenavMode = 'side';
      this.coreService.sidenavOpen = true;
      const mainDiv = document.getElementsByClassName('app');
      for (let i = 0; i < mainDiv.length; i++) {
        if (mainDiv[i].classList.contains('sidebar-overlay')) {
          document.getElementById('main-app').classList.remove('sidebar-overlay');
        }
      }
    } else {
      this.coreService.sidenavMode = 'over';
      this.coreService.sidenavOpen = false;
      const mainDiv = document.getElementsByClassName('app');
      for (let i = 0; i < mainDiv.length; i++) {
        if (!(mainDiv[i].classList.contains('sidebar-overlay'))) {
          document.getElementById('main-app').className += ' sidebar-overlay';
        }
      }
    }
  }

  resizeSideBar() {
    if (this.windowSize < 1200) {
      this.isMobileStatus = true;
      this.isMobile = this.isMobileStatus;
      this.coreService.sidenavMode = 'over';
      this.coreService.sidenavOpen = false;
      const mainDiv = document.getElementsByClassName('app');
      for (let i = 0; i < mainDiv.length; i++) {
        if (!(mainDiv[i].classList.contains('sidebar-overlay'))) {
          if (document.getElementById('main-app')) {
            document.getElementById('main-app').className += ' sidebar-overlay';
          }
        }
      }
    } else {
      this.isMobileStatus = false;
      this.isMobile = this.isMobileStatus;
      this.coreService.sidenavMode = 'side';
      this.coreService.sidenavOpen = true;
      const mainDiv = document.getElementsByClassName('app');
      for (let i = 0; i < mainDiv.length; i++) {
        if (mainDiv[i].classList.contains('sidebar-overlay')) {
          document.getElementById('main-app').classList.remove('sidebar-overlay');
        }
      }
    }
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$)).subscribe(
        () => this.utilsService.translate('PUBLISH SUCCESS').subscribe(text => this.logger.success(text))
      )
    );
  }
}
