import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';

@Injectable({
  providedIn: 'root'
})
export class RegoloService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'regolo/refrigerante';
  }
}

export class RefrigeranteRegolo {
  id: number;
  name: string;
  nameOrComposition: string;
  application: string;
  ashrae: string;
  dateOfProhibition: string;
  fluidGroup: string;
  gwp: string;
  replacement: string;
  suitableOil: string;
  family: any[];
  famiglia: string;
}
