<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="refrigerant" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        [colCount]="2">
        <dxi-item dataField="name" [label]="{ text: 'NAME' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG NAME' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="ashrae" [label]="{ text: 'ASHRAE' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="fluidGroup" [label]="{ text: 'FLUID GROUP' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="gwp" [label]="{ text: 'GWP' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="nameOrComposition" [label]="{ text: 'NAME OR COMPOSITION' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="family" editorType="dxTagBox" [editorOptions]="familyEditorOptions$ | async"
          [label]="{ text: 'FAMILY' | translate }" [colSpan]="2"></dxi-item>
        <dxi-item dataField="replacement" [label]="{ text: 'REPLACEMENT' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="suitableOil" [label]="{ text: 'SUITABLE OIL' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="dateOfProhibition" editorType="dxTextArea" [editorOptions]="{ height: 150 }"
          [label]="{ text: 'DATE OF PROHIBITION' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="application" editorType="dxTextArea" [editorOptions]="{ height: 150 }"
          [label]="{ text: 'APPLICATIONS' | translate }" [colSpan]="1"></dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid">
          </dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>