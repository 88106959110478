import { ConnettoreService } from './../../connettore/connettore.service';
import { Espansione, EspansioneService } from './../espansione.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { AppApiService } from 'app/common/app-api.service';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxFormComponent } from 'devextreme-angular';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ms-form-espansione',
  templateUrl: './form-espansione.component.html',
  styleUrls: ['./form-espansione.component.scss']
})
export class FormEspansioneComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  expansionValve: Espansione;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isDescriptionValid: boolean;
  id: any;
  photo: any;
  types: string[];
  omologazioneTypes: string[];
  familyEditorOptions$: Observable<any>;
  connectorsEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private espansioneService: EspansioneService,
    private connettoreService: ConnettoreService,
    private dxService: DxService,
    private appApiService: AppApiService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isDescriptionValid = false;
  }

  ngOnInit() {
    this.types = this.configService.getEspansioneTypes();
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.familyEditorOptions$ = this.dxService.dxiItemEditorOptions(this.appApiService.getFamilies(), 'value', 'id');
    this.connectorsEditorOptions$ = this.dxService.dxiItemEditorOptions(this.connettoreService.getList(), 'numero_catalogo', 'this');
    this.disposeBag.add(this.getExpansionValve().subscribe(expansionValve => {
      delete expansionValve.famiglia;
      if (!expansionValve.descrizione) { expansionValve.descrizione = '{}'; }
      this.expansionValve = expansionValve;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.expansionValve); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.espansioneService.create(this.expansionValve, this.photo) : this.espansioneService.update(this.id, this.expansionValve, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['expansion_valves']);
  }

  private getExpansionValve(): Observable<Espansione> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT EXPANSION VALVE';
        return this.espansioneService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD EXPANSION VALVE';
        return of(new Espansione());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) { return false; }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isDescriptionValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
