import { ConfigService } from 'app/common/config.service';
import { ConnettoreService } from './../../connettore/connettore.service';
import { DxService } from './../../../common/dx.service';
import { BobinaService } from './../bobina.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Bobina } from '../bobina.service';
import { DxFormComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { LoadingService } from 'app/common/loading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from 'app/common/utils.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ms-form-bobina',
  templateUrl: './form-bobina.component.html',
  styleUrls: ['./form-bobina.component.scss']
})
export class FormBobinaComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  coil: Bobina;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  id: any;
  photo: any;
  currentTypes: string[];
  omologazioneTypes: string[];
  connectorsEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private bobinaService: BobinaService,
    private dxService: DxService,
    private connettoreService: ConnettoreService,
    private configService: ConfigService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('COILS');
    this.currentTypes = this.configService.getCorrenteTypes();
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.connectorsEditorOptions$ = this.dxService.dxiItemEditorOptions(this.connettoreService.getList(), 'numero_catalogo', 'this');
    this.disposeBag.add(this.getCoil().subscribe(coil => {
      this.coil = coil;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.coil); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) { this.isFormInvalid = !this.isFormValid(); }
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.bobinaService.create(this.coil, this.photo) : this.bobinaService.update(this.id, this.coil, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['coils']);
  }

  private getCoil(): Observable<Bobina> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT COIL';
        return this.bobinaService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD COIL';
        return of(new Bobina());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
