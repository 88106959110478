import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) { }

  getFamilies(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('famiglia_solenoide')).map(res => res as any);
  }

  getCoilSeries(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('bobina', 'serie')).map(res => res as any);
  }

  publishSelectorData(): Observable<any> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('synchronization', 'publish'));
  }

  publishRulerData(): Observable<any> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('regolo', 'synchronization', 'publish'));
  }
}
