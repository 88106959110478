<dx-data-grid [dataSource]="secondParameters$ | async" allowColumnReordering="true" rowAlternationEnabled="true"
  allowColumnResizing="true" showBorders="true" columnHidingEnabled="true"
  (onToolbarPreparing)="onToolbarPreparing($event)">
  <dxo-export enabled="true" fileName="Second Parameters"></dxo-export>
  <dxo-filter-row visible="true"></dxo-filter-row>
  <dxo-header-filter visible="true"></dxo-header-filter>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-paging pageSize="10"></dxo-paging>
  <dxo-pager showPageSizeSelector="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
  <dxo-search-panel visible="true" highlightCaseSensitive="true"></dxo-search-panel>
  <dxo-group-panel visible="true"></dxo-group-panel>
  <dxo-grouping autoExpandAll="false"></dxo-grouping>
  <dxi-column type="buttons" [width]="buttonWidth">
    <dxi-button icon="trash" [onClick]="deleteButtonClick" hint="{{ 'DELETE SECOND PARAMETER' | translate }}">
    </dxi-button>
    <dxi-button icon="edit" [onClick]="editButtonClick" hint="{{ 'EDIT SECOND PARAMETER' | translate }}"></dxi-button>
  </dxi-column>
  <dxi-column dataField="caduta_pressione" caption="{{ 'PRESSURE DROP' | translate }}" alignment="left"></dxi-column>
  <dxi-column dataField="nome_fattore" caption="{{ 'PARAMETER' | translate }}"></dxi-column>
  <dxi-column dataField="fattore" caption="{{ 'VALUE' | translate }}" alignment="left"></dxi-column>
</dx-data-grid>
<ms-form-second-parameter-popup [(visible)]="popupVisible" [id]="id" (afterClosed)="afterClosed($event)">
</ms-form-second-parameter-popup>