import { InterpolationDataService } from './components/refrigerante/interpolation-data/interpolation-data.service';
import { AttuatoreService } from './components/attuatore/attuatore.service';
import { RubinettoService } from './components/rubinetto/rubinetto.service';
import { InterpolationDataRegoloService } from './components/regolo/interpolation-data-regolo/interpolation-data-regolo.service';
import { SecondParameterService } from './components/second-parameter/second-parameter.service';
import { ThirdParameterService } from './components/refrigerante/third-parameter/third-parameter.service';
import { FirstParameterService } from './components/refrigerante/first-parameter/first-parameter.service';
import { ResaFrigoriferaService } from './components/refrigerante/resa-frigorifera/resa-frigorifera.service';
import { RefrigeranteService } from './components/refrigerante/refrigerante.service';
import { ConnettoreService } from './components/connettore/connettore.service';
import { BobinaService } from './components/bobina/bobina.service';
import { AppApiService } from './common/app-api.service';
import { RitegnoService } from './components/ritegno/ritegno.service';
import { SolenoideService } from './components/solenoide/solenoide.service';
import { DxService } from './common/dx.service';
import { MtLoadingModule } from './common/mt-loading/mt-loading.module';
import { UserService } from './components/user/user.service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import { ToastrModule } from 'ngx-toastr';
import { RoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { PageTitleService } from './core/page-title/page-title.service';
import { GeneAppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { SideBarComponent } from './side-bar/side-bar.component';
import { UserComponent } from './components/user/user.component';
import { GridUserComponent } from './components/user/grid-user/grid-user.component';
import {
  DxDataGridModule, DxButtonModule, DxFormModule, DxFileUploaderModule, DxPopupModule, DxTabPanelModule, DxScrollViewModule, DxTextAreaModule, DxTagBoxModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule
} from 'devextreme-angular';
import { FormUserComponent } from './components/user/form-user/form-user.component';
import { UtilsService } from './common/utils.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { TokenInterceptor } from './authentication/token-interceptor';
import { ErrorInterceptor } from './authentication/error-Interceptor';
import { FormPasswordComponent } from './components/user/form-password/form-password.component';
import { GridBaseComponent } from './classes/grid-base.component';
import { SolenoideComponent } from './components/solenoide/solenoide.component';
import { RitegnoComponent } from './components/ritegno/ritegno.component';
import { BobinaComponent } from './components/bobina/bobina.component';
import { ConnettoreComponent } from './components/connettore/connettore.component';
import { RefrigeranteComponent } from './components/refrigerante/refrigerante.component';
import { GridSolenoideComponent } from './components/solenoide/grid-solenoide/grid-solenoide.component';
import { FormSolenoideComponent } from './components/solenoide/form-solenoide/form-solenoide.component';
import { BlobImageUrlPipe } from './common/blob-image-url.pipe';
import { GridRitegnoComponent } from './components/ritegno/grid-ritegno/grid-ritegno.component';
import { FormRitegnoComponent } from './components/ritegno/form-ritegno/form-ritegno.component';
import { GridConnettoreComponent } from './components/connettore/grid-connettore/grid-connettore.component';
import { FormConnettoreComponent } from './components/connettore/form-connettore/form-connettore.component';
import { GridBobinaComponent } from './components/bobina/grid-bobina/grid-bobina.component';
import { FormBobinaComponent } from './components/bobina/form-bobina/form-bobina.component';
import { GridRefrigeranteComponent } from './components/refrigerante/grid-refrigerante/grid-refrigerante.component';
import { FormRefrigerantePopupComponent } from './components/refrigerante/form-refrigerante-popup/form-refrigerante-popup.component';
import { ResaFrigoriferaComponent } from './components/refrigerante/resa-frigorifera/resa-frigorifera.component';
import {
  FormResaFrigoriferaPopupComponent
} from './components/refrigerante/resa-frigorifera/form-resa-frigorifera-popup/form-resa-frigorifera-popup.component';
import { GridFirstParameterComponent } from './components/refrigerante/first-parameter/grid-first-parameter/grid-first-parameter.component';
import {
  FormFirstParameterPopupComponent
} from './components/refrigerante/first-parameter/form-first-parameter-popup/form-first-parameter-popup.component';
import { GridThirdParameterComponent } from './components/refrigerante/third-parameter/grid-third-parameter/grid-third-parameter.component';
import {
  FormThirdParameterPopupComponent
} from './components/refrigerante/third-parameter/form-third-parameter-popup/form-third-parameter-popup.component';
import { SecondParameterComponent } from './components/second-parameter/second-parameter.component';
import { GridSecondParameterComponent } from './components/second-parameter/grid-second-parameter/grid-second-parameter.component';
import {
  FormSecondParameterPopupComponent
} from './components/second-parameter/form-second-parameter-popup/form-second-parameter-popup.component';
import { LoginComponent } from './login/login.component';
import { FormCsvPopupComponent } from './common/form-csv-popup/form-csv-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { RegoloComponent } from './components/regolo/regolo.component';
import { GridRefrigeranteRegoloComponent } from './components/regolo/grid-refrigerante-regolo/grid-refrigerante-regolo.component';
import { RegoloService } from './components/regolo/regolo.service';
import { FormRefrigeranteRegoloComponent } from './components/regolo/form-refrigerante-regolo/form-refrigerante-regolo.component';
import { GridInterpolationDataRegoloComponent } from './components/regolo/interpolation-data-regolo/grid-interpolation-data-regolo/grid-interpolation-data-regolo.component';
import { FormInterpolationDataRegoloPopupComponent } from './components/regolo/interpolation-data-regolo/form-interpolation-data-regolo-popup/form-interpolation-data-regolo-popup.component';
import { IndicatoreComponent } from './components/indicatore/indicatore.component';
import { IndicatoreService } from './components/indicatore/indicatore.service';
import { GridIndicatoreComponent } from './components/indicatore/grid-indicatore/grid-indicatore.component';
import { FormIndicatoreComponent } from './components/indicatore/form-indicatore/form-indicatore.component';
import { RubinettoComponent } from './components/rubinetto/rubinetto.component';
import { GridRubinettoComponent } from './components/rubinetto/grid-rubinetto/grid-rubinetto.component';
import { FormRubinettoComponent } from './components/rubinetto/form-rubinetto/form-rubinetto.component';
import { AdattatoreComponent } from './components/adattatore/adattatore.component';
import { AdattatoreService } from './components/adattatore/adattatore.service';
import { GridAdattatoreComponent } from './components/adattatore/grid-adattatore/grid-adattatore.component';
import { FormAdattatorePopupComponent } from './components/adattatore/form-adattatore-popup/form-adattatore-popup.component';
import { AttuatoreComponent } from './components/attuatore/attuatore.component';
import { GridAttuatoreComponent } from './components/attuatore/grid-attuatore/grid-attuatore.component';
import { FormAttuatoreComponent } from './components/attuatore/form-attuatore/form-attuatore.component';
import { EspansioneComponent } from './components/espansione/espansione.component';
import { EspansioneService } from './components/espansione/espansione.service';
import { GridEspansioneComponent } from './components/espansione/grid-espansione/grid-espansione.component';
import { FormEspansioneComponent } from './components/espansione/form-espansione/form-espansione.component';
import { GridMopdComponent } from './common/grid-mopd/grid-mopd.component';
import { QuadroComponent } from './components/quadro/quadro.component';
import { QuadroService } from './components/quadro/quadro.service';
import { GridQuadroComponent } from './components/quadro/grid-quadro/grid-quadro.component';
import { FormQuadroComponent } from './components/quadro/form-quadro/form-quadro.component';
import { GridInterpolationDataComponent } from './components/refrigerante/interpolation-data/grid-interpolation-data/grid-interpolation-data.component';
import { FormInterpolationDataPopupComponent } from './components/refrigerante/interpolation-data/form-interpolation-data-popup/form-interpolation-data-popup.component';
import { CapacitaFrigoriferaService } from './components/refrigerante/capacita-frigorifera/capacita-frigorifera.service';
import { GridCapacitaFrigoriferaComponent } from './components/refrigerante/capacita-frigorifera/grid-capacita-frigorifera/grid-capacita-frigorifera.component';
import { FormCapacitaFrigoriferaPopupComponent } from './components/refrigerante/capacita-frigorifera/form-capacita-frigorifera-popup/form-capacita-frigorifera-popup.component';
import { TextBoxMultilanguageComponent } from './common/text-box-multilanguage/text-box-multilanguage.component';
import { TextAreaMultilanguageComponent } from './common/text-area-multilanguage/text-area-multilanguage.component';
import { SondaComponent } from './components/sonda/sonda.component';
import { SondaService } from './components/sonda/sonda.service';
import { GridSondaComponent } from './components/sonda/grid-sonda/grid-sonda.component';
import { FormSondaComponent } from './components/sonda/form-sonda/form-sonda.component';
import { GridFilterParameterComponent } from './components/refrigerante/filter-parameter/grid-filter-parameter/grid-filter-parameter.component';
import { FilterParameterService } from './components/refrigerante/filter-parameter/filter-parameter.service';
import { FormFilterParameterPopupComponent } from './components/refrigerante/filter-parameter/form-filter-parameter-popup/form-filter-parameter-popup.component';
import { FiltroComponent } from './components/filtro/filtro.component';
import { FiltroService } from './components/filtro/filtro.service';
import { GridFiltroComponent } from './components/filtro/grid-filtro/grid-filtro.component';
import { FormFiltroComponent } from './components/filtro/form-filtro/form-filtro.component';
import { CapacitaFiltroService } from './components/filtro/capacita-filtro/capacita-filtro.service';
import { GridCapacitaFiltroComponent } from './components/filtro/capacita-filtro/grid-capacita-filtro/grid-capacita-filtro.component';
import { FormCapacitaFiltroPopupComponent } from './components/filtro/capacita-filtro/form-capacita-filtro-popup/form-capacita-filtro-popup.component';
import { CapacitaDisidratanteService } from './components/capacita-disidratante/capacita-disidratante.service';
import { GridCapacitaDisidratanteComponent } from './components/capacita-disidratante/grid-capacita-disidratante/grid-capacita-disidratante.component';
import { FormCapacitaDisidratantePopupComponent } from './components/capacita-disidratante/form-capacita-disidratante-popup/form-capacita-disidratante-popup.component';
import { CartucciaComponent } from './components/cartuccia/cartuccia.component';
import { CartucciaService } from './components/cartuccia/cartuccia.service';
import { GridCartucciaComponent } from './components/cartuccia/grid-cartuccia/grid-cartuccia.component';
import { FormCartucciaComponent } from './components/cartuccia/form-cartuccia/form-cartuccia.component';
import { SicurezzaComponent } from './components/sicurezza/sicurezza.component';
import { SicurezzaService } from './components/sicurezza/sicurezza.service';
import { GridSicurezzaComponent } from './components/sicurezza/grid-sicurezza/grid-sicurezza.component';
import { FormSicurezzaComponent } from './components/sicurezza/form-sicurezza/form-sicurezza.component';
import { ConnessioneComponent } from './components/connessione/connessione.component';
import { GridConnessioneComponent } from './components/connessione/grid-connessione/grid-connessione.component';
import { GridFParameterComponent } from './components/refrigerante/f-parameter/grid-f-parameter/grid-f-parameter.component';
import { FormFParameterPopupComponent } from './components/refrigerante/f-parameter/form-f-parameter-popup/form-f-parameter-popup.component';
import { FParameterService } from './components/refrigerante/f-parameter/f-parameter.service';
import { GridCapacitaVaporizzazioneComponent } from './components/refrigerante/capacita-vaporizzazione/grid-capacita-vaporizzazione/grid-capacita-vaporizzazione.component';
import { FormCapacitaVaporizzazionePopupComponent } from './components/refrigerante/capacita-vaporizzazione/form-capacita-vaporizzazione-popup/form-capacita-vaporizzazione-popup.component';
import { CapacitaVaporizzazioneService } from './components/refrigerante/capacita-vaporizzazione/capacita-vaporizzazione.service';
import { GridPortataComponent } from './components/sicurezza/portata/grid-portata/grid-portata.component';
import { FormPortataPopupComponent } from './components/sicurezza/portata/form-portata-popup/form-portata-popup.component';
import { PortataService } from './components/sicurezza/portata/portata.service';
import { RubinettoSicurezzaComponent } from './components/rubinetto-sicurezza/rubinetto-sicurezza.component';
import { GridRubinettoSicurezzaComponent } from './components/rubinetto-sicurezza/grid-rubinetto-sicurezza/grid-rubinetto-sicurezza.component';
import { FormRubinettoSicurezzaComponent } from './components/rubinetto-sicurezza/form-rubinetto-sicurezza/form-rubinetto-sicurezza.component';
import { RubinettoSicurezzaService } from './components/rubinetto-sicurezza/rubinetto-sicurezza.service';
import { MaterialeComponent } from './components/materiale/materiale.component';
import { GridMaterialeComponent } from './components/materiale/grid-materiale/grid-materiale.component';
import { MaterialeService } from './components/materiale/materiale.service';
import { FormConnessioneComponent } from './components/connessione/form-connessione/form-connessione.component';
import { FormMaterialeComponent } from './components/materiale/form-materiale/form-materiale.component';
import { GridCapacitaScaricoComponent } from './components/sicurezza/capacita-scarico/grid-capacita-scarico/grid-capacita-scarico.component';
import { FormCapacitaScaricoPopupComponent } from './components/sicurezza/capacita-scarico/form-capacita-scarico-popup/form-capacita-scarico-popup.component';
import { CapacitaScaricoService } from './components/sicurezza/capacita-scarico/capacita-scarico.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('it');
      const browserLang: string = translate.getBrowserLang();
      const langToSet = browserLang.match(/en|it/) ? browserLang : 'it'
      translate.use(langToSet).subscribe(
        () => { },
        () => console.error(`Problem with '${langToSet}' language initialization.'`),
        () => resolve(null)
      );
    });
  });
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RoutingModule,
    FlexLayoutModule,
    NgbModalModule,
    Ng5BreadcrumbModule.forRoot(),
    PerfectScrollbarModule,
    MenuToggleModule,
    HttpClientModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    ToastrModule.forRoot(),
    LoadingBarRouterModule,
    LoadingBarRouterModule,
    DxDataGridModule,
    DxButtonModule,
    DxFormModule,
    DxFileUploaderModule,
    DxPopupModule,
    DxTabPanelModule,
    DxScrollViewModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTagBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    AuthenticationModule,
    MtLoadingModule
  ],
  declarations: [
    GeneAppComponent,
    MainComponent,
    SideBarComponent,
    UserComponent,
    GridUserComponent,
    FormUserComponent,
    FormPasswordComponent,
    GridBaseComponent,
    SolenoideComponent,
    RitegnoComponent,
    BobinaComponent,
    ConnettoreComponent,
    RefrigeranteComponent,
    GridSolenoideComponent,
    FormSolenoideComponent,
    BlobImageUrlPipe,
    GridRitegnoComponent,
    FormRitegnoComponent,
    GridBobinaComponent,
    FormBobinaComponent,
    GridConnettoreComponent,
    FormConnettoreComponent,
    GridRefrigeranteComponent,
    FormRefrigerantePopupComponent,
    ResaFrigoriferaComponent,
    FormResaFrigoriferaPopupComponent,
    GridFirstParameterComponent,
    FormFirstParameterPopupComponent,
    SecondParameterComponent,
    GridSecondParameterComponent,
    FormSecondParameterPopupComponent,
    GridThirdParameterComponent,
    FormThirdParameterPopupComponent,
    LoginComponent,
    GridMopdComponent,
    FormCsvPopupComponent,
    RegoloComponent,
    GridRefrigeranteRegoloComponent,
    FormRefrigeranteRegoloComponent,
    GridInterpolationDataRegoloComponent,
    FormInterpolationDataRegoloPopupComponent,
    IndicatoreComponent,
    GridIndicatoreComponent,
    FormIndicatoreComponent,
    RubinettoComponent,
    GridRubinettoComponent,
    FormRubinettoComponent,
    AdattatoreComponent,
    GridAdattatoreComponent,
    FormAdattatorePopupComponent,
    AttuatoreComponent,
    GridAttuatoreComponent,
    FormAttuatoreComponent,
    EspansioneComponent,
    GridEspansioneComponent,
    FormEspansioneComponent,
    QuadroComponent,
    GridQuadroComponent,
    FormQuadroComponent,
    GridInterpolationDataComponent,
    FormInterpolationDataPopupComponent,
    GridCapacitaFrigoriferaComponent,
    FormCapacitaFrigoriferaPopupComponent,
    TextBoxMultilanguageComponent,
    TextAreaMultilanguageComponent,
    SondaComponent,
    GridSondaComponent,
    FormSondaComponent,
    GridFilterParameterComponent,
    FormFilterParameterPopupComponent,
    FiltroComponent,
    GridFiltroComponent,
    FormFiltroComponent,
    GridCapacitaFiltroComponent,
    FormCapacitaFiltroPopupComponent,
    GridCapacitaDisidratanteComponent,
    FormCapacitaDisidratantePopupComponent,
    CartucciaComponent,
    GridCartucciaComponent,
    FormCartucciaComponent,
    SicurezzaComponent,
    GridSicurezzaComponent,
    FormSicurezzaComponent,
    ConnessioneComponent,
    GridConnessioneComponent,
    GridFParameterComponent,
    FormFParameterPopupComponent,
    GridCapacitaVaporizzazioneComponent,
    FormCapacitaVaporizzazionePopupComponent,
    GridPortataComponent,
    FormPortataPopupComponent,
    RubinettoSicurezzaComponent,
    GridRubinettoSicurezzaComponent,
    FormRubinettoSicurezzaComponent,
    MaterialeComponent,
    GridMaterialeComponent,
    FormConnessioneComponent,
    FormMaterialeComponent,
    GridCapacitaScaricoComponent,
    FormCapacitaScaricoPopupComponent
  ],
  bootstrap: [GeneAppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    MenuItems,
    BreadcrumbService,
    PageTitleService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    UtilsService,
    DxService,
    AppApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    UserService,
    SolenoideService,
    RitegnoService,
    BobinaService,
    ConnettoreService,
    RefrigeranteService,
    ResaFrigoriferaService,
    FirstParameterService,
    SecondParameterService,
    ThirdParameterService,
    RegoloService,
    InterpolationDataRegoloService,
    IndicatoreService,
    RubinettoService,
    AdattatoreService,
    AttuatoreService,
    EspansioneService,
    QuadroService,
    InterpolationDataService,
    CapacitaFrigoriferaService,
    SondaService,
    FilterParameterService,
    FiltroService,
    CapacitaFiltroService,
    CapacitaDisidratanteService,
    CartucciaService,
    SicurezzaService,
    ConnettoreService,
    FParameterService,
    CapacitaVaporizzazioneService,
    PortataService,
    RubinettoSicurezzaService,
    MaterialeService,
    CapacitaScaricoService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeneAppModule { }
