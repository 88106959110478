import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import { Observable } from 'rxjs';
import { Connettore } from '../connettore/connettore.service';

@Injectable({
  providedIn: 'root'
})
export class BobinaService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'bobina';
  }

  create(coil: Bobina, file: any): Observable<any> {
    const formData = this.setFormData(coil, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, coil: Bobina, file: any): Observable<any> {
    const formData = this.setFormData(coil, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(coil: Bobina, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(coil));
    if (!!file) {
      formData.append('file', file);
    }
    return formData;
  }
}

export class Bobina {
  id: number;
  numero_catalogo: string;
  tipo: string;
  tensione: string;
  serie: string;
  tipo_corrente: string;
  tolleranza_tensione: string;
  frequenza: string;
  classe_isolamento: string;
  ta_min: number;
  ta_max: number;
  collegamento: string;
  grado_protezione: string;
  potenza: number;
  omologazioni: string[];
  connettori: Connettore[];
  photo_id: number;
  photo_url: string;
}

export enum CorrenteTypes {
  AC = 'AC',
  DC = 'DC',
  RAC = 'RAC'
}
