import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapacitaFiltroService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'capacita_filtro';
  }

  getByFiltroId(filterId: number): Observable<CapacitaFiltro[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, 'filtro', filterId.toString()));
  }

  importFromCsv(filterId: number, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.requestService.multipartRequestWithFormData(
      'PUT',
      this.apiService.createUrlFromBaseUrl(this.target, 'filtro', filterId.toString(), 'csv'),
      formData,
      true
    );
  }
}

export class CapacitaFiltro {
  id: number;
  refrigerante_id: number;
  filtro_id: number;
  caduta_pressione: number;
  valore: number;
  parametro: string;
}
