import { InterpolationDataRegoloService, InterpolationDataRegolo } from './../interpolation-data-regolo.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { Observable } from 'rxjs';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'ms-grid-interpolation-data-regolo',
  templateUrl: './grid-interpolation-data-regolo.component.html',
  styleUrls: ['./grid-interpolation-data-regolo.component.scss']
})
export class GridInterpolationDataRegoloComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  @Input() refrigerantId: number;
  disposeBag = new DisposeBag();
  interpolationData$: Observable<InterpolationDataRegolo[]>;
  id: any;
  popupVisible: boolean;
  filePopupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private interpolationDataService: InterpolationDataRegoloService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
    this.filePopupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.handleRequest(this.deleteWithConfirm(this.interpolationDataService.delete(event.row.data.id)));
  }

  afterClosed(event: InterpolationDataRegolo) {
    if (!!event) {
      event.refrigeranteId = this.refrigerantId;
      if (!!event.temperature && !!event.temperatureLiquid && !!event.temperatureVapor) {
        event.temperature = null;
      }
      const observable$ = this.isNew() ? this.interpolationDataService.insert(event) : this.interpolationDataService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  afterFileClosed(event: File) {
    if (!!event) {
      this.handleRequest(this.withConfirm(this.interpolationDataService.importFromCsv(this.refrigerantId, event), 'IMPORT CSV ALERT MSG'));
    }
  }

  openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  openFilePopup() {
    this.filePopupVisible = true;
  }

  private loadData() {
    this.interpolationData$ = this.interpolationDataService.getByRefrigerantId(this.refrigerantId);
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(observable$.subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
