import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { Observable, EMPTY } from 'rxjs';
import { ResaFrigorifera, ResaFrigoriferaService } from './resa-frigorifera.service';
import { LoadingService } from 'app/common/loading.service';
import { DxService } from 'app/common/dx.service';

@Component({
  selector: 'ms-resa-frigorifera',
  templateUrl: './resa-frigorifera.component.html',
  styleUrls: ['./resa-frigorifera.component.scss']
})
export class ResaFrigoriferaComponent implements OnInit, OnDestroy {
  @Input() refrigerantId: number;
  disposeBag = new DisposeBag();
  refrigerationYield: ResaFrigorifera;
  id: any;
  popupVisible: boolean;
  addButtonVisible: boolean;

  constructor(
    private dxService: DxService,
    private loadingService: LoadingService,
    private resaFrigoriferaService: ResaFrigoriferaService
  ) {
    this.popupVisible = false;
    this.addButtonVisible = false;
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  openPopup(id?: number) {
    this.id = id;
    this.popupVisible = true;
  }

  delete(id: number) {
    this.handleRequest(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', this.resaFrigoriferaService.delete(id.toString()))
    );
  }

  afterClosed(event: ResaFrigorifera) {
    if (!!event) {
      event.refrigerante_id = this.refrigerantId;
      const observable$ = this.isNew() ? this.resaFrigoriferaService.insert(event) : this.resaFrigoriferaService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  private loadData() {
    this.disposeBag.add(this.resaFrigoriferaService.getFilteredByRefrigerantId(this.refrigerantId).subscribe(res => {
      this.refrigerationYield = res;
      this.addButtonVisible = !this.refrigerationYield;
    }));
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
