import { RefrigeranteRegolo, RegoloService } from './../regolo.service';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-grid-refrigerante-regolo',
  templateUrl: './grid-refrigerante-regolo.component.html',
  styleUrls: ['./grid-refrigerante-regolo.component.scss']
})
export class GridRefrigeranteRegoloComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  refrigerants$: Observable<RefrigeranteRegolo[]>;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private router: Router,
    private regoloService: RegoloService
  ) {
    super(configService, dxService, loadingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.router.navigate(['ruler_refrigerants', event.row.data.id]);
  }

  deleteButtonClick(event: any) {
    this.disposeBag.add(this.deleteWithConfirm(this.regoloService.delete(event.row.data.id)).subscribe(() => this.loadData()));
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.router.navigate(['ruler_refrigerants/add'])
      }
    });
  }

  private loadData() {
    this.refrigerants$ = this.loadingService.apiRequest$WithLoading(this.regoloService.getList().map(list => {
      return list.map((refrigerant: RefrigeranteRegolo) => {
        refrigerant.famiglia = refrigerant.family.map(item => item.value).join(', ');
        return refrigerant
      });
    }));
  }
}
