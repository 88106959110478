import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Subject } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { DisposeBag } from '../../classes/dispose-bag.class';

@Component({
  selector: 'ms-mt-loading',
  templateUrl: './mt-loading.component.html',
  styleUrls: ['./mt-loading.component.scss']
})
export class MtLoadingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() show: string;
  disposeBag = new DisposeBag();
  loading: Subject<boolean>;
  loading$: Observable<boolean>;

  constructor(private loadingBar: LoadingBarService) {
    this.loading = new Subject<boolean>();
    this.loading$ = this.loading.asObservable().pipe(delay(0))
  }

  ngOnInit() {
    this.stop();
    this.disposeBag.add(this.loading$.subscribe(ev => this.handleShow(ev)));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes) {
      const loading = changes.show.currentValue;
      this.loading.next(loading);
    }
  }

  private handleShow(ev: boolean) {
    !!ev ? this.start() : this.stop();
  }

  private start() {
    this.loadingBar.start();
  }

  private stop() {
    this.loadingBar.complete();
  }
}
