import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ThirdParameter, ThirdParameterService } from '../third-parameter.service';
import { Observable } from 'rxjs';
import { DxService } from 'app/common/dx.service';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-form-third-parameter-popup',
  templateUrl: './form-third-parameter-popup.component.html',
  styleUrls: ['./form-third-parameter-popup.component.scss']
})
export class FormThirdParameterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @Input() id: string;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<ThirdParameter>();
  disposeBag = new DisposeBag();
  isVisible: boolean;
  thirdParameter: ThirdParameter;
  isFormInvalid: boolean;
  isContentReady: boolean;
  popupTitle$: Observable<string>;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;

  constructor(private dxService: DxService, private utils: UtilsService, private thirdParameterService: ThirdParameterService) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.onFormSubmit());
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  onHiding() {
    this.closePopup();
  }

  onShowing() {
    if (this.isNew()) {
      this.thirdParameter = new ThirdParameter();
    } else {
      this.disposeBag.add(this.thirdParameterService.getOne(this.id).subscribe(
        thirdParameter => {
          this.thirdParameter = thirdParameter;
          this.dxForm.instance.updateData(this.thirdParameter);
        },
        () => this.closePopup()
      ));
    }
  }

  onShown() {
    this.popupTitle$ = this.isNew() ? this.utils.translate('ADD THIRD PARAMETER') : this.utils.translate('EDIT THIRD PARAMETER');
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) {
      this.isFormInvalid = !this.isFormValid();
    }
  }

  private onFormSubmit() {
    const data = Object.assign({}, this.thirdParameter);
    this.closePopup(data);
  }

  private closePopup(thirdParameter?: ThirdParameter) {
    this.setVisible(false);
    this.afterClosed.emit(thirdParameter);
    this.isFormInvalid = true;
    this.popupTitle$ = null;
    this.thirdParameter = null;
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
