import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdattatoreService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'adattatore';
  }

  create(adapter: Adattatore, file: any): Observable<any> {
    const formData = this.setFormData(adapter, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, adapter: Adattatore, file: any): Observable<any> {
    const formData = this.setFormData(adapter, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(adapter: Adattatore, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(adapter));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Adattatore {
  id: number;
  numero_catalogo: string;
  photo_id: number;
  photo_url: string;
  photo?: any;
}