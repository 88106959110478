<dx-popup [maxWidth]="600" [maxHeight]="500" title="{{ 'IMPORT FROM CSV' | translate }}" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="!csvFile">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <div [innerHtml]="text"></div>
    <div class="fileuploader-container">
      <dx-file-uploader selectButtonText="{{ 'SELECT FILE' | translate }}" labelText=""
        accept="text/csv" uploadMode="useForm" (valueChange)="fileChange($event)">
      </dx-file-uploader>
    </div>
  </dx-scroll-view>
</dx-popup>