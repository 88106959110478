import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Sicurezza, SicurezzaService, SicurezzaTypes } from '../sicurezza.service';

@Component({
  selector: 'ms-form-sicurezza',
  templateUrl: './form-sicurezza.component.html',
  styleUrls: ['./form-sicurezza.component.scss']
})
export class FormSicurezzaComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  safetyValve: Sicurezza;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isDescriptionValid: boolean;
  id: any;
  photo: any;
  types: SicurezzaTypes[];
  omologazioneTypes: string[];

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private sicurezzaService: SicurezzaService,
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isDescriptionValid = false;
    this.types = Object.values(SicurezzaTypes);
  }

  ngOnInit() {
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.disposeBag.add(this.getSafetyValve().subscribe(safetyValve => {
      if (!safetyValve.descrizione) { safetyValve.descrizione = '{}'; }
      this.safetyValve = safetyValve;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.safetyValve); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ? this.sicurezzaService.create(this.safetyValve, this.photo)
        : this.sicurezzaService.update(this.id, this.safetyValve, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['safety_valves']);
  }

  private getSafetyValve(): Observable<Sicurezza> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT SAFETY VALVE';
        return this.sicurezzaService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD SAFETY VALVE';
        return of(new Sicurezza());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) { return false; }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isDescriptionValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
