import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { Connessione, ConnessioneService } from '../connessione.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from 'app/common/loading.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ms-form-connessione',
  templateUrl: './form-connessione.component.html',
  styleUrls: ['./form-connessione.component.scss']
})
export class FormConnessioneComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  connection: Connessione;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isTypeValid: boolean;
  id: any;
  photo: any;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private connessioneService: ConnessioneService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isTypeValid = false;
  }

  ngOnInit() {
    this.disposeBag.add(this.getConnection().subscribe(connection => {
      if (!connection.tipo) {
        connection.tipo = '{}';
      }
      this.connection = connection;
      if (!this.isNew()) {
        this.dxForm.instance.updateData(this.connection);
      }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.connessioneService.create(this.connection, this.photo) : this.connessioneService.update(this.id, this.connection, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['connections']);
  }

  private getConnection(): Observable<Connessione> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT CONNECTION';
        return this.connessioneService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD CONNECTION';
        return of(new Connessione());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) {
      return false;
    }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isTypeValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
