import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { DxService } from 'app/common/dx.service';
import { UtilsService } from 'app/common/utils.service';
import { RefrigeranteService } from 'app/components/refrigerante/refrigerante.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { Portata, PortataService, PortataTypes } from '../portata.service';

@Component({
  selector: 'ms-form-portata-popup',
  templateUrl: './form-portata-popup.component.html',
  styleUrls: ['./form-portata-popup.component.scss']
})
export class FormPortataPopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @Input() id: string;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<Portata>();
  disposeBag = new DisposeBag();
  isVisible: boolean;
  flowRate: Portata;
  isFormInvalid: boolean;
  isContentReady: boolean;
  popupTitle$: Observable<string>;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;
  refrigerantEditorOptions$: Observable<any>;
  types: PortataTypes[];

  constructor(
    private dxService: DxService,
    private utils: UtilsService,
    private portataService: PortataService,
    private refrigeranteService: RefrigeranteService,
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.types = Object.values(PortataTypes);
  }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.onFormSubmit());
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  onHiding() {
    this.closePopup();
  }

  onShowing() {
    this.refrigerantEditorOptions$ = this.dxService.dxiItemEditorOptions(this.refrigeranteService.getList(), 'name', 'id', !this.isNew());
    if (this.isNew()) {
      this.flowRate = new Portata();
    } else {
      this.disposeBag.add(this.portataService.getOne(this.id).subscribe(
        flowRate => {
          this.flowRate = flowRate;
          this.dxForm.instance.updateData(this.flowRate);
        },
        () => this.closePopup()
      ));
    }
  }

  onShown() {
    this.popupTitle$ = this.isNew() ? this.utils.translate('ADD FLOW RATE') : this.utils.translate('EDIT FLOW RATE');
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) {
      this.isFormInvalid = !this.isFormValid();
    }
  }

  private onFormSubmit() {
    const data = Object.assign({}, this.flowRate);
    this.closePopup(data);
  }

  private closePopup(flowRate?: Portata) {
    this.setVisible(false);
    this.afterClosed.emit(flowRate);
    this.isFormInvalid = true;
    this.popupTitle$ = null;
    this.flowRate = null;
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
