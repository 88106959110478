import { Injectable } from '@angular/core';
import { from, Observable, EMPTY } from 'rxjs';
import { UtilsService } from './utils.service';
import { confirm } from 'devextreme/ui/dialog';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DxService {
  constructor(private utils: UtilsService) { }

  dxiItemEditorOptions(dataObservable$: Observable<any>, displayField: string, valueField: string, disabled = false): Observable<any> {
    return dataObservable$.map(dataArray => {
      return { dataSource: dataArray, displayExpr: displayField, valueExpr: valueField, disabled };
    });
  }

  dxToolbarItemOptions(text: string, onClick: any): Observable<any> {
    return this.utils.translate(text).map(translatedText => {
      return { text: translatedText, onClick: onClick };
    });
  }

  dxConfirmWithSuccessfulObservable(title: string, messageHtml: string, successfulObservable$: Observable<any>): Observable<any> {
    return this.dxConfirm(title, messageHtml).pipe(switchMap(res => res ? successfulObservable$ : EMPTY));
  }

  dxConfirm(title: string, messageHtml: string): Observable<boolean> {
    return this.utils.translateArray([title, messageHtml]).pipe(
      switchMap(([tsTitle, tsMessageHtml]) => from(confirm(tsMessageHtml, tsTitle)))
    );
  }

}
