import { Injectable } from '@angular/core';

@Injectable()
export class MenuItems {
  private getAll(): Menu[] {
    const MENUITEMS = [
      {
        state: 'users',
        name: 'USERS',
        type: 'link',
        icon: 'group',
        roles: ['admin']
      }, {
        state: '',
        name: 'SELECTOR',
        type: 'sub',
        icon: 'description',
        roles: ['admin'],
        children: [
          {
            state: 'solenoids',
            name: 'SOLENOIDS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'check_valves',
            name: 'CHECK VALVES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'indicators',
            name: 'INDICATORS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'ball_valves',
            name: 'BALL VALVES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'expansion_valves',
            name: 'EXPANSION VALVES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'filters',
            name: 'FILTERS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'safety_valves',
            name: 'SAFETY VALVES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'electrical_boards',
            name: 'ELECTRICAL BOARDS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'coils',
            name: 'COILS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'actuators',
            name: 'ACTUATORS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'cores',
            name: 'CORES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'safety_valve_accessories',
            name: 'SAFETY VALVE ACCESSORIES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'connectors',
            name: 'CONNECTORS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'probes',
            name: 'PROBES',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'adapters',
            name: 'ADAPTERS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'connections',
            name: 'CONNECTIONS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'materials',
            name: 'MATERIALS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'refrigerants',
            name: 'SELECTOR REFRIGERANTS',
            type: 'link',
            roles: ['admin']
          }, {
            state: 'second_parameters',
            name: 'SECOND PARAMETERS',
            type: 'link',
            roles: ['admin']
          }
        ]
      }, {
        state: '',
        name: 'RULER',
        type: 'sub',
        icon: 'note',
        roles: ['admin'],
        children: [
          {
            state: 'ruler_refrigerants',
            name: 'RULER REFRIGERANTS',
            type: 'link',
            roles: ['admin']
          }
        ]
      }
    ];
    return MENUITEMS;
  }

  getByRole(role: string): Menu[] {
    return this.getAll().filter(menu => {
      if (!!menu.children && menu.children.length > 0) {
        menu.children = menu.children.filter(child => {
          return child.roles.includes(role);
        });
      }
      return menu.roles.includes(role);
    });
  }

  add(menu: any) {
    this.getAll().push(menu);
  }
}

export interface ChildrenItems {
  state: string;
  name: string;
  roles: string[];
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  roles: string[];
  children?: ChildrenItems[];
}
