import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';
import { BobinaMOPD } from 'app/common/grid-mopd/bobina-mopd.class';

@Injectable({
  providedIn: 'root'
})
export class EspansioneService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'espansione';
  }

  create(expansionValve: Espansione, file: any): Observable<any> {
    const formData = this.setFormData(expansionValve, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, expansionValve: Espansione, file: any): Observable<any> {
    const formData = this.setFormData(expansionValve, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(expansionValve: Espansione, file: any): FormData {
    expansionValve.descrizione = JSON.stringify(expansionValve.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(expansionValve));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Espansione {
  id: number;
  numero_catalogo: string;
  attacco_ods_in_inch: string;
  attacco_ods_out_inch: string;
  attacco_ods_in_mm: number;
  attacco_ods_out_mm: number;
  porta_equivalente: number;
  tipo_orifizio: number;
  kv: number;
  min_opd: number;
  m_opd: number;
  ps: number;
  ts_min: number;
  ts_max: number;
  ta_min: number;
  ta_max: number;
  tempo_minimo_intervento: number;
  dimensione_corpo: number;
  principi_funzionamento: string;
  categoria_rischio: string;
  descrizione: string;
  tipo: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;
  linea_id: number;
  famiglia: any;
  espansione_bobina_MOPD: EspansioneBobinaMOPD[];

  constructor() {
    this.attacco_ods_in_mm = null;
    this.attacco_ods_out_mm = null;
    this.porta_equivalente = null;
    this.tipo_orifizio = null;
    this.kv = null;
    this.min_opd = null;
    this.m_opd = null;
    this.ps = null;
    this.ts_min = null;
    this.ts_max = null;
    this.ta_min = null;
    this.ta_max = null;
    this.tempo_minimo_intervento = null;
    this.dimensione_corpo = null;
    this.espansione_bobina_MOPD = [];
  }
}

export class EspansioneBobinaMOPD extends BobinaMOPD {
  espansione_id: number;
}

export enum EspansioneTypes {
  STEPPER = 'Stepper',
  PULSE = 'Pulse'
}
