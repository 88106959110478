<div fxLayout="row wrap" fxLayoutAlign="start" class="content-margin">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <span class="title">{{ 'REFRIGERATION YIELD' | translate }}</span>
    <dx-button icon="edit" (click)="openPopup(refrigerationYield.id)" type="default" stylingMode="text"
      hint="{{ 'EDIT REFRIGERATION YIELD' | translate }}" [visible]="!!refrigerationYield"></dx-button>
    <dx-button icon="trash" (click)="delete(refrigerationYield.id)" type="default" stylingMode="text"
      hint="{{ 'DELETE REFRIGERATION YIELD' | translate }}" [visible]="!!refrigerationYield"></dx-button>
  </div>
  <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100" *ngIf="!!refrigerationYield">
    <div>
      <div class="label-text">{{ 'LIQUID LINE' | translate }}</div>
      <div class="text">{{ refrigerationYield.linea_liquido }}</div>
    </div>
  </div>
  <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100" *ngIf="!!refrigerationYield">
    <div>
      <div class="label-text">{{ 'SUCTION LINE' | translate }}</div>
      <div class="text">{{ refrigerationYield.linea_aspirazione }}</div>
    </div>
  </div>
  <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100" *ngIf="!!refrigerationYield">
    <div>
      <div class="label-text">{{ 'HOT GAS LINE' | translate }}</div>
      <div class="text">{{ refrigerationYield.linea_gas_caldo }}</div>
    </div>
  </div>
  <dx-button [elementAttr]="{ class: 'button-margin' }" text="{{ 'ADD REFRIGERATION YIELD' | translate }}"
    (onClick)="openPopup()" [visible]="addButtonVisible"></dx-button>
</div>
<ms-form-resa-frigorifera-popup [(visible)]="popupVisible" [id]="id" (afterClosed)="afterClosed($event)">
</ms-form-resa-frigorifera-popup>