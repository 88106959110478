import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.baseUrl;

  constructor() { }

  createUrlFromBaseUrl(...elements: string[]): string {
    return this.createUrlFromUrl(this.baseUrl, elements);
  }

  private createUrlFromUrl(url: string, elements: string[]): string {
    elements.unshift(url);
    return elements.join('/');
  }
}
