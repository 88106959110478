<dx-popup [maxWidth]="600" [maxHeight]="450" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="refrigerant" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()">
      <dxi-item dataField="name" [label]="{ text: 'NAME' | translate }">
        <dxi-validation-rule type="required" message="{{ 'MSG NAME' | translate }}"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="famiglia_solenoide_id" editorType="dxSelectBox"
        [editorOptions]="familyEditorOptions$ | async" [label]="{text: 'MAIN FAMILY' | translate}">
        <dxi-validation-rule type="required" [message]="'MSG FAMILY' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="famiglia_rubinetto_id" editorType="dxSelectBox"
      [editorOptions]="familyEditorOptions$ | async" [label]="{text: 'SECOND FAMILY' | translate}">
      <dxi-validation-rule type="required" [message]="'MSG FAMILY' | translate"></dxi-validation-rule>
    </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>