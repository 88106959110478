import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(public snackBar: MatSnackBar, private translate: TranslateService) { }

  info(message: string) {
    this.log(message, 'info');
  }

  warning(message: string) {
    this.log(message, 'warning');
  }

  success(message: string) {
    this.log(message, 'success');
  }

  error(message: string) {
    this.log(message, 'error');
  }

  unknownError() {
    this.translate.get('UNKNOWN ERROR').subscribe((translatedText: string) => this.error(translatedText.toUpperCase()));
  }
  serverError() {
    this.translate.get('SERVER ERROR').subscribe((translatedText: string) => this.error(translatedText));
  }

  conflictError() {
    this.translate.get('CONFLICT ERROR').subscribe((translatedText: string) => this.error(translatedText.toUpperCase()));
  }

  notAccetableError() {
    this.translate.get('NOTACCETABLE ERROR').subscribe((translatedText: string) => this.error(translatedText.toUpperCase()));
  }

  unauthorizedError() {
    this.translate.get('UNAUTHORIZED ERROR').subscribe((translatedText: string) => this.error(translatedText.toUpperCase()));
  }

  badRequestError() {
    this.translate.get('BADREQUEST ERROR').subscribe((translatedText: string) => this.error(translatedText.toUpperCase()));
  }

  private log(message: string, type: string) {
    let extraClasses = [];
    switch (type) {
      case 'success': {
        extraClasses = ['toast-dark', 'toast-success'];
        break;
      }
      case 'info': {
        extraClasses = ['toast-light', 'toast-assert'];
        break;
      }
      case 'warning': {
        extraClasses = ['toast-light', 'toast-warning', 'toast-text-dark'];
        break;
      }
      case 'error': {
        extraClasses = ['toast-dark', 'toast-error', 'toast-text-dark'];
        break;
      }
      default: break;
    }
    this.translate.get(message).subscribe(
      (translatedText: string) => this.openSnackBar(translatedText, extraClasses),
      () => this.openSnackBar(message, extraClasses)
    );
  }

  private openSnackBar(message: string, extraClasses: any) {
    this.snackBar.open(message, this.translate.instant('CLOSE').toUpperCase(), {
      duration: 3000,
      panelClass: extraClasses
    });
  }
}
