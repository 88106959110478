import { AppApiService } from './../../../common/app-api.service';
import { UtilsService } from '../../../common/utils.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable, zip } from 'rxjs';
import { RefrigeranteSelector, RefrigeranteService } from '../refrigerante.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { DxService } from 'app/common/dx.service';

@Component({
  selector: 'ms-grid-refrigerante',
  templateUrl: './grid-refrigerante.component.html',
  styleUrls: ['./grid-refrigerante.component.scss']
})
export class GridRefrigeranteComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  refrigerants$: Observable<RefrigeranteSelector[]>;
  sections$: Observable<any[]>;
  id: any;
  popupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private refrigeranteService: RefrigeranteService,
    private utilsService: UtilsService,
    private appApiService: AppApiService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
    this.sections$ = this.utilsService.translateArrayDictWithIds(this.configService.getRefrigerantTabItems());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.disposeBag.add(this.deleteWithConfirm(this.refrigeranteService.delete(event.row.data.id)).subscribe(() => this.loadData()));
  }

  afterClosed(event: RefrigeranteSelector) {
    if (!!event) {
      const observable$ = this.isNew() ? this.refrigeranteService.insert(event) : this.refrigeranteService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.openPopup()
      }
    });
  }

  private openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  private loadData() {
    this.refrigerants$ = this.loadingService.apiRequest$WithLoading(
      zip(this.refrigeranteService.getList(), this.appApiService.getFamilies())
    ).map(([refrigerants, families]) => {
      const familiesDict = this.utilsService.arrayToDict(families, 'id', 'value');
      return refrigerants.map((refrigerant: RefrigeranteSelector) => {
        refrigerant.famiglia_solenoide = familiesDict[refrigerant.famiglia_solenoide_id];
        refrigerant.famiglia_rubinetto = familiesDict[refrigerant.famiglia_rubinetto_id];
        return refrigerant;
      });
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
