import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-rubinetto-sicurezza',
  templateUrl: './rubinetto-sicurezza.component.html',
  styleUrls: ['./rubinetto-sicurezza.component.scss']
})
export class RubinettoSicurezzaComponent  implements OnInit {
  constructor(public loadingService: LoadingService, private utils: UtilsService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('SAFETY VALVE ACCESSORIES');
  }
}
