import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import 'devextreme-intl';
const itMessages = require('devextreme/localization/messages/it.json');
import { locale, loadMessages } from 'devextreme/localization';

@Component({
   selector: 'gene-app',
   template: `<router-outlet></router-outlet>`,
   encapsulation: ViewEncapsulation.None
})

export class GeneAppComponent {
   constructor(translate: TranslateService) {
      translate.addLangs(['en', 'fr', 'he', 'ru', 'ar', 'zh', 'de', 'es', 'ja', 'ko', 'it', 'hu']);
      translate.setDefaultLang('it');
      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
      loadMessages(itMessages);
      locale(navigator.language || browserLang);
   }
}
