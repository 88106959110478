<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="filter" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        [colCount]="4">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="famiglia" editorType="dxSelectBox" [editorOptions]="{ dataSource: families }"
          [label]="{ text: 'FAMILY' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG FAMILY' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
          [label]="{ text: 'TYPE' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="linea_id" editorType="dxSelectBox" [editorOptions]="lineEditorOptions$ | async"
          [label]="{ text: 'LINE' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="numero_cartucce" editorType="dxNumberBox" [label]="{ text: 'CORES NUMBER' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="riferimento" [label]="{ text: 'INTERNATIONAL REFERENCE' | translate }"
          [colSpan]="2"></dxi-item>
        <dxi-item dataField="apertura" editorType="dxNumberBox" [label]="{ text: 'MESH OPENING' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="superficie_filtrante" editorType="dxNumberBox"
          [label]="{ text: 'BLOCK FILTERING SURFACE CM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="passaggio_superficie" editorType="dxNumberBox"
          [label]="{ text: 'USEFUL PASSAGE SURFACE' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="cubatura_inch" editorType="dxNumberBox"
          [label]="{ text: 'NOMINAL VOLUME INCH' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="cubatura_cm" editorType="dxNumberBox" [label]="{ text: 'NOMINAL VOLUME CM' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_sae" [label]="{ text: 'SAE CONNECTION INCH' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="attacco_ods_inch" [label]="{ text: 'ODS CONNECTION INCH' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="attacco_ods_mm" editorType="dxNumberBox"
          [label]="{ text: 'ODS CONNECTION MM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_odm_inch" [label]="{ text: 'ODM CONNECTION INCH' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="attacco_odm_mm" editorType="dxNumberBox"
          [label]="{ text: 'ODM CONNECTION MM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_w_mm" editorType="dxNumberBox" [label]="{ text: 'W CONNECTION MM' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="kv" editorType="dxNumberBox" [label]="{ text: 'KV' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="ps" editorType="dxNumberBox" [label]="{ text: 'PS' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="ts_min" editorType="dxNumberBox" [label]="{ text: 'TS MIN' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ts_max" editorType="dxNumberBox" [label]="{ text: 'TS MAX' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ta_min" editorType="dxNumberBox" [label]="{ text: 'TA MIN' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ta_max" editorType="dxNumberBox" [label]="{ text: 'TA MAX' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="d1" editorType="dxNumberBox" [label]="{ text: 'D1' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="d2" editorType="dxNumberBox" [label]="{ text: 'D2' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="h1" editorType="dxNumberBox" [label]="{ text: 'H1' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="h2" editorType="dxNumberBox" [label]="{ text: 'H2' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="h3" editorType="dxNumberBox" [label]="{ text: 'H3' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="l" editorType="dxNumberBox" [label]="{ text: 'L' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="p" editorType="dxNumberBox" [label]="{ text: 'P' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="q" editorType="dxNumberBox" [label]="{ text: 'Q' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="peso" editorType="dxNumberBox" [label]="{ text: 'WEIGHT' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="categoria_rischio" [label]="{ text: 'RISK CATEGORY' | translate }" [colSpan]="2">
        </dxi-item>
        <dxi-item dataField="cartucce" editorType="dxTagBox" [editorOptions]="coresEditorOptions$ | async"
          [label]="{ text: 'CORES' | translate }" [colSpan]="2"></dxi-item>
        <dxi-item dataField="omologazioni" editorType="dxTagBox" [editorOptions]="{ dataSource: omologazioneTypes }"
          [label]="{ text: 'HOMOLOGATIONS' | translate }" [colSpan]="2"></dxi-item>
        <dxi-item *ngIf="!!filter" itemType="group" [colSpan]="4">
          <dxi-item [label]="{ text: 'DESCRIPTION' | translate }">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="filter.descrizione" [(isValid)]="isDescriptionValid"
                [isRequired]="false" (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <img *ngIf="!!filter.photo_url" class="previewImg" [attr.src]="filter.photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                  accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>