<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div> <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="gene-card-content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <fieldset style="border: none">
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput placeholder="{{ 'NAME' | translate }}" formControlName="name">
              </mat-form-field>
              <small class="gene-block"
                *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                class="mat-text-warn">
                {{ 'MSG NAME' | translate }}
              </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput placeholder="{{ 'SURNAME' | translate }}" formControlName="surname">
              </mat-form-field>
              <small class="gene-block"
                *ngIf="form.controls['surname'].hasError('required') && form.controls['surname'].touched"
                class="mat-text-warn">
                {{ 'MSG SURNAME' | translate }}
              </small>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput placeholder="{{ 'MOBILE' | translate }}" formControlName="mobile">
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput placeholder="{{ 'PHONE' | translate }}" formControlName="phone">
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput placeholder="{{ 'E-MAIL' | translate }}" formControlName="email">
              </mat-form-field>
              <small class="gene-block"
                *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                class="mat-text-warn">
                {{ 'MSG E-MAIL' | translate }}
              </small>
            </div>
            <div *ngIf="isNew()" fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput type="password" placeholder="{{ 'PASSWORD' | translate }}" formControlName="password">
              </mat-form-field>
              <small class="gene-block" *ngIf="validatePassword()" class="mat-text-warn">
                {{ 'MSG PASSWORD' | translate }}
              </small>
            </div>
            <div *ngIf="!isMe()" fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100">
              <div class="mrgn-b-lg">
                <mat-checkbox formControlName="enabled">{{ 'ENABLED' | translate }}</mat-checkbox>
              </div>
            </div>
            <div *ngIf="!isNew()" fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100">
              <div class="mrgn-b-lg">
                <dx-button text="{{ 'PASSWORD' | translate }}" (onClick)="setPassword()" stylingMode="text"
                  onclick="this.blur()"></dx-button>
              </div>
            </div>
            <div *ngIf="isNew()" fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput type="password" placeholder="{{ 'CONFIRM PASSWORD' | translate }}"
                  formControlName="confirm_password">
              </mat-form-field>
              <small class="gene-block" *ngIf="matchPassword()" class="mat-text-warn">
                {{ 'MSG CONFIRM PASSWORD' | translate }}
              </small>
            </div>
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field class="full-wid mrgn-b-lg">
                <input matInput placeholder="{{ 'COMPANY' | translate }}" formControlName="company">
              </mat-form-field>
              <small class="gene-block"
                *ngIf="form.controls['company'].hasError('required') && form.controls['company'].touched"
                class="mat-text-warn">
                {{ 'MSG COMPANY' | translate }}
              </small>
            </div>
          </div>
        </fieldset>
        <div class="pad-wrap">
          <mat-card-actions>
            <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
              [disabled]="!form.valid">
            </dx-button>
            <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
          </mat-card-actions>
        </div>
      </form>
    </div>
  </mat-card>
</div>