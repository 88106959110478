import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, finalize, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading: Subject<boolean>;
  progress: Subject<number>;
  loading$: Observable<boolean>;
  progress$: Observable<number>;

  constructor() {
    this.loading = new Subject<boolean>();
    this.progress = new Subject<number>();
    this.loading$ = this.loading.asObservable().pipe(delay(0));
    this.progress$ = this.progress.asObservable().pipe(delay(0));
  }

  apiRequest$WithLoading(apiRequest$: Observable<any>): Observable<any> {
    this.updateLoading(true);
    return apiRequest$.pipe(finalize(() => this.updateLoading(false)));
  }

  apiRequest$WithLoadingAndProgress(apiRequest$: Observable<any>): Observable<any> {
    this.updateLoading(true);
    return apiRequest$.pipe(
      tap(ev => this.handleProgress(ev)),
      finalize(() => this.updateLoading(false))
    ).filter(event => event instanceof HttpResponse);
  }

  private updateLoading(loading: boolean) {
    this.loading.next(loading);
  }

  private handleProgress(event: any) {
    if (event.loaded < event.total) {
      const percentDone = Math.round(100 * event.loaded / event.total);
      this.updateProgress(percentDone);
    } else {
      this.updateProgress(0);
    }
  }

  private updateProgress(progress: number) {
    this.progress.next(progress);
  }
}
