import { RefrigeranteRegolo, RegoloService } from './../regolo.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { AppApiService } from 'app/common/app-api.service';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'ms-form-refrigerante-regolo',
  templateUrl: './form-refrigerante-regolo.component.html',
  styleUrls: ['./form-refrigerante-regolo.component.scss']
})
export class FormRefrigeranteRegoloComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  refrigerant: RefrigeranteRegolo;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  id: any;
  familyEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private regoloService: RegoloService,
    private dxService: DxService,
    private appApiService: AppApiService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('RULER REFRIGERANTS');
    this.familyEditorOptions$ = this.dxService.dxiItemEditorOptions(this.appApiService.getFamilies(), 'value', 'this');
    this.disposeBag.add(this.getRefrigerant().subscribe(refrigerant => {
      this.refrigerant = refrigerant;
      if (!this.isNew()) {
        this.dxForm.instance.updateData(this.refrigerant);
      }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) {
      this.isFormInvalid = !this.isFormValid();
    }
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.regoloService.insert(this.refrigerant) : this.regoloService.put(this.refrigerant, this.id);
      this.handleRequest(observable$);
    }
  }

  goToListPage() {
    this.router.navigate(['ruler_refrigerants']);
  }

  private getRefrigerant(): Observable<RefrigeranteRegolo> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT REFRIGERANT';
        return this.regoloService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD REFRIGERANT';
        const refrigerant = new RefrigeranteRegolo();
        return of(refrigerant);
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
