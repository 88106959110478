import { Adattatore, AdattatoreService } from './../adattatore.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-grid-adattatore',
  templateUrl: './grid-adattatore.component.html',
  styleUrls: ['./grid-adattatore.component.scss']
})
export class GridAdattatoreComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  adapters$: Observable<Adattatore[]>;
  id: any;
  popupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private adattatoreService: AdattatoreService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.disposeBag.add(this.deleteWithConfirm(this.adattatoreService.delete(event.row.data.id)).subscribe(() => this.loadData()));
  }

  afterClosed(event: Adattatore) {
    if (!!event) {
      const photo = event.photo;
      delete event['photo'];
      const observable$ = this.isNew() ? this.adattatoreService.create(event, photo) : this.adattatoreService.update(this.id, event, photo);
      this.handleRequest(observable$);
    }
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.openPopup()
      }
    });
  }

  private openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  private loadData() {
    this.adapters$ = this.loadingService.apiRequest$WithLoading(this.adattatoreService.getList());
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
