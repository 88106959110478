<dx-popup [maxWidth]="600" [maxHeight]="700" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="flowRate" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()">
      <dxi-item dataField="refrigerante_id" editorType="dxSelectBox" [editorOptions]="refrigerantEditorOptions$ | async"
        [label]="{ text: 'REFRIGERANT' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG REFRIGERANT' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="portata" editorType="dxNumberBox" [label]="{ text: 'FLOW RATE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG FLOW RATE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="volume" editorType="dxNumberBox" [label]="{ text: 'VOLUME' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG VOLUME' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="pressione" editorType="dxNumberBox" [label]="{ text: 'PRESSURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG PRESSURE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="temperatura" editorType="dxNumberBox" [label]="{ text: 'TEMPERATURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG TEMPERATURE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="coefficiente" editorType="dxNumberBox" [label]="{ text: 'EXP COEFFICIENT' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG EXP COEFFICIENT' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
        [label]="{ text: 'TYPE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>