import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnessioneService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'connessione';
  }

  create(connection: Connessione, file: any): Observable<any> {
    const formData = this.setFormData(connection, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, connection: Connessione, file: any): Observable<any> {
    const formData = this.setFormData(connection, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(connection: Connessione, file: any): FormData {
    connection.tipo = JSON.stringify(connection.tipo);
    const formData = new FormData();
    formData.append('data', JSON.stringify(connection));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Connessione {
  id: number;
  tipo: string;
  perdita_carico: number;
  photo_id: number;
  photo_url: string;
  photo?: any;

  constructor() {
    this.perdita_carico = null;
  }
}
