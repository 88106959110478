<dx-popup [maxWidth]="600" [maxHeight]="600" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="firstParameter" (onFieldDataChanged)="onFieldDataChanged()"
      (onContentReady)="onContentReady()">
      <dxi-item dataField="temperatura_condensazione" editorType="dxNumberBox"
        [label]="{ text: 'CONDENSATION TEMPERATURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG CONDENSATION TEMPERATURE' | translate">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="temperatura_evaporazione" editorType="dxNumberBox"
        [label]="{ text: 'EVAPORATION TEMPERATURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG EVAPORATION TEMPERATURE' | translate">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="parametro" [label]="{ text: 'PARAMETER' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG PARAMETER' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="value" editorType="dxNumberBox" [label]="{ text: 'VALUE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG VALUE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="pressione" editorType="dxNumberBox" [label]="{ text: 'DISCHARGE PRESSURE' | translate }">
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>