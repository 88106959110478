<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="connection" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()">
        <dxi-item *ngIf="!!connection" [label]="{ text: 'TYPE' | translate }">
          <div *dxTemplate>
            <ms-text-box-multilanguage [(model)]="connection.tipo" [(isValid)]="isTypeValid" [isRequired]="true"
              [errorMessage]="'MSG TYPE' | translate"
              (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
          </div>
        </dxi-item>
        <dxi-item dataField="perdita_carico" editorType="dxNumberBox" [label]="{ text: 'LOAD LOSS' | translate }">
          <dxi-validation-rule type="required" [message]="'MSG LOAD LOSS' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item>
          <div *dxTemplate>
            <img *ngIf="!!connection && !!connection.photo_url" class="previewImg"
              [attr.src]="connection.photo_url | blobImageUrl | async" />
            <div class="fileuploader-container">
              <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>