import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-indicatore',
  templateUrl: './indicatore.component.html',
  styleUrls: ['./indicatore.component.scss']
})
export class IndicatoreComponent implements OnInit {
  constructor(public loadingService: LoadingService, private utils: UtilsService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('INDICATORS');
  }
}