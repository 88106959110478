import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SicurezzaService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'sicurezza';
  }

  create(safetyValve: Sicurezza, file: any): Observable<any> {
    const formData = this.setFormData(safetyValve, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, safetyValve: Sicurezza, file: any): Observable<any> {
    const formData = this.setFormData(safetyValve, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(safetyValve: Sicurezza, file: any): FormData {
    safetyValve.descrizione = JSON.stringify(safetyValve.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(safetyValve));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Sicurezza {
  id: number;
  numero_catalogo: string;
  tipo: string;
  attacco_in: string;
  attacco_out: string;
  diametro: number;
  area: number;
  corsa: number;
  pendenza_asme: number;
  coefficiente_efflusso: number;
  ps: number;
  pset_min: number;
  pset_max: number;
  ts_min: number;
  ts_max: number;
  tolleranza_pressione: number;
  sovrapressione: number;
  scarto_richiusura: number;
  tenuta: number;
  c: number;
  h1: number;
  h2: number;
  h3: number;
  coppia_chiave_collegamento_min: number;
  coppia_chiave_collegamento_max: number;
  peso: number;
  descrizione: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;

  constructor() {
    this.diametro = null;
    this.area = null;
    this.corsa = null;
    this.pendenza_asme = null;
    this.coefficiente_efflusso = null;
    this.ps = null;
    this.pset_min = null;
    this.pset_max = null;
    this.ts_min = null;
    this.ts_max = null;
    this.tolleranza_pressione = null;
    this.sovrapressione = null;
    this.scarto_richiusura = null;
    this.tenuta = null;
    this.c = null;
    this.h1 = null;
    this.h2 = null;
    this.h3 = null;
    this.coppia_chiave_collegamento_min = null;
    this.coppia_chiave_collegamento_max = null;
    this.peso = null;
  }
}

export enum SicurezzaTypes {
  EN_ISO_DIS = 'EN 13136 / ISO-DIS 24664',
  ASME = 'ASME'
}
