import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { SecondParameter, SecondParameterService } from '../second-parameter.service';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';

@Component({
  selector: 'ms-grid-second-parameter',
  templateUrl: './grid-second-parameter.component.html',
  styleUrls: ['./grid-second-parameter.component.scss']
})
export class GridSecondParameterComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  secondParameters$: Observable<SecondParameter[]>;
  id: any;
  popupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private secondParameterService: SecondParameterService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.disposeBag.add(this.deleteWithConfirm(this.secondParameterService.delete(event.row.data.id)).subscribe(() => this.loadData()));
  }

  afterClosed(event: SecondParameter) {
    if (!!event) {
      const observable$ = this.isNew() ? this.secondParameterService.insert(event) : this.secondParameterService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.openPopup()
      }
    });
  }

  private openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  private loadData() {
    this.secondParameters$ = this.loadingService.apiRequest$WithLoading(this.secondParameterService.getList());
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
