<ms-mt-loading [show]="loading"></ms-mt-loading>
<div fxLayout="row" fxLayoutAlign="center start" class="height-full pad-t-xl">
	<div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="60" fxFlex.sm="70" fxFlex.xs="100">
		<mat-card class="gene-login-wrapper">
			<div>
				<div fxLayout="column" fxLayoutAlign="center center mrgn-b-md">
					<div class="login-logo justify-content">
						<img src="assets/img/logo.png">
					</div>
				</div>
				<br />
				<form #form="ngForm" (ngSubmit)="login()">
					<div fxLayout="column" fxLayoutAlign="start stretch">
						<mat-form-field class="mrgn-b-md">
							<input matInput placeholder="{{ 'E-MAIL' | translate }}" type="text" name="email" required
								[(ngModel)]="email">
						</mat-form-field>
						<mat-form-field class="mrgn-b-md">
							<input matInput placeholder="{{ 'PASSWORD' | translate }}" type="password" name="password" required
								[(ngModel)]="password">
						</mat-form-field>
						<button class="mat-green white-text" mat-raised-button
							[disabled]="!form.valid">{{ 'LOGIN' | translate }}</button>
						<mat-error *ngIf="isErrorShow != null && isErrorShow">
							{{ 'LOGIN ERROR' | translate }}
						</mat-error>
					</div>
				</form>
			</div>
		</mat-card>
	</div>
</div>