<dx-popup [maxWidth]="600" [maxHeight]="540" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="interpolationData" (onFieldDataChanged)="onFieldDataChanged()"
      (onContentReady)="onContentReady()">
      <dxi-item dataField="pression" editorType="dxNumberBox" [label]="{ text: 'PRESSURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG PRESSURE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="temperature" editorType="dxNumberBox" [label]="{ text: 'TEMPERATURE' | translate }">
      </dxi-item>
      <dxi-item dataField="temperatureLiquid" editorType="dxNumberBox"
        [label]="{ text: 'LIQUID TEMPERATURE' | translate }">
        <dxi-validation-rule *ngIf="!!interpolationData && !!interpolationData.temperatureVapor" type="required"
          [message]="'MSG LIQUID TEMPERATURE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="temperatureVapor" editorType="dxNumberBox"
        [label]="{ text: 'VAPOR TEMPERATURE' | translate }">
        <dxi-validation-rule *ngIf="!!interpolationData && !!interpolationData.temperatureLiquid" type="required"
          [message]="'MSG VAPOR TEMPERATURE' | translate"></dxi-validation-rule>
      </dxi-item>
    </dx-form>
    <div *ngIf="!!interpolationData && !isTemperatureSelected()" class="field-required-error">
      {{ 'MSG ONE TEMPERATURE' | translate }}</div>
  </dx-scroll-view>
</dx-popup>