import { LoadingService } from './../common/loading.service';
import { DxService } from 'app/common/dx.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../common/config.service';

@Component({
  selector: 'app-base',
  template: `NO UI TO BE FOUND HERE!`,
  styleUrls: ['./grid-base.component.css']
})
export class GridBaseComponent implements OnInit {
  buttonWidth: number;
  numericWidth: number;
  shortTextWidth: number;
  timestampWidth: number;
  elapsedTimeWidth: number;
  userFullNameWidth: number;
  imageWidth: number;

  constructor(public configService: ConfigService, public dxService: DxService, public loadingService: LoadingService) { }

  ngOnInit() {
    this.buttonWidth = +this.configService.getButtonWidth();
    this.numericWidth = +this.configService.getNumericWidth();
    this.shortTextWidth = +this.configService.getShortTextWidth();
    this.timestampWidth = +this.configService.getTimestampWidth();
    this.elapsedTimeWidth = +this.configService.getElapsedTimeWidth();
    this.userFullNameWidth = +this.configService.getUserFullNameWidth();
    this.imageWidth = +this.configService.getImageWidth();
  }

  withConfirm(observable$: Observable<any>, message: string) {
    return this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', message, observable$)
    );
  }

  deleteWithConfirm(observable$: Observable<any>) {
    return this.withConfirm(observable$, 'ARE YOU SURE')
  }
}
