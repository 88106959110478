import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { Observable } from 'rxjs';
import { FirstParameter, FirstParameterService } from '../first-parameter.service';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';

@Component({
  selector: 'ms-grid-first-parameter',
  templateUrl: './grid-first-parameter.component.html',
  styleUrls: ['./grid-first-parameter.component.scss']
})
export class GridFirstParameterComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  @Input() refrigerantId: number;
  disposeBag = new DisposeBag();
  firstParameters$: Observable<FirstParameter[]>;
  id: any;
  popupVisible: boolean;
  filePopupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private firstParameterService: FirstParameterService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
    this.filePopupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.handleRequest(this.deleteWithConfirm(this.firstParameterService.delete(event.row.data.id)));
  }

  afterClosed(event: FirstParameter) {
    if (!!event) {
      event.refrigerante_id = this.refrigerantId;
      const observable$ = this.isNew() ? this.firstParameterService.insert(event) : this.firstParameterService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  afterFileClosed(event: File) {
    if (!!event) {
      this.handleRequest(this.withConfirm(this.firstParameterService.importFromCsv(this.refrigerantId, event), 'IMPORT CSV ALERT MSG'));
    }
  }

  openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  openFilePopup() {
    this.filePopupVisible = true;
  }

  private loadData() {
    this.firstParameters$ = this.firstParameterService.getByRefrigerantId(this.refrigerantId);
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(observable$.subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
