import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RitegnoService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'ritegno';
  }

  create(checkValve: Ritegno, file: any): Observable<any> {
    const formData = this.setFormData(checkValve, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, checkValve: Ritegno, file: any): Observable<any> {
    const formData = this.setFormData(checkValve, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(checkValve: Ritegno, file: any): FormData {
    checkValve.descrizione = JSON.stringify(checkValve.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(checkValve));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Ritegno {
  id: number;
  disegno: string;
  numero_catalogo: string;
  attacco_sae: string;
  attacco_ods_inch: string;
  attacco_ods_mm: number;
  attacco_w_mm: number;
  kv: number;
  minOPD: number;
  ps: number;
  ts_min: number;
  ts_max: number;
  categoria_rischio: string;
  linea: string;
  descrizione: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;

  constructor() {
    this.attacco_ods_mm = null;
    this.attacco_w_mm = null;
    this.kv = null;
    this.minOPD = null;
    this.ps = null;
    this.ts_min = null;
    this.ts_max = null;
  }
}

export enum RitegnoLineaTypes {
  ERMETICA = 'Ermetica',
  CO2 = 'CO2'
}

export enum DisegnoTypes {
  LINEA = 'Linea',
  SQUADRA = 'Squadra'
}
