<dx-data-grid [dataSource]="vaporizationCapacities$ | async" allowColumnReordering="true" rowAlternationEnabled="true"
  allowColumnResizing="true" showBorders="true" columnHidingEnabled="true">
  <dxo-filter-row visible="true"></dxo-filter-row>
  <dxo-header-filter visible="true"></dxo-header-filter>
  <dxo-paging pageSize="10"></dxo-paging>
  <dxo-pager showPageSizeSelector="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
  <dxi-column type="buttons" [width]="buttonWidth">
    <dxi-button icon="trash" [onClick]="deleteButtonClick" hint="{{ 'DELETE VAPORIZATION CAPACITY' | translate }}">
    </dxi-button>
    <dxi-button icon="edit" [onClick]="editButtonClick" hint="{{ 'EDIT VAPORIZATION CAPACITY' | translate }}">
    </dxi-button>
  </dxi-column>
  <dxi-column dataField="valore" caption="{{ 'VALUE' | translate }}" alignment="left"></dxi-column>
  <dxi-column dataField="pressione" caption="{{ 'PRESSURE' | translate }}" alignment="left"></dxi-column>
</dx-data-grid>
<dx-button [elementAttr]="{ class: 'button-margin' }" text="{{ 'IMPORT FROM CSV' | translate }}" type="default"
  (onClick)="openFilePopup()"></dx-button>
<dx-button [elementAttr]="{ class: 'button-margin' }" text="{{ 'ADD VAPORIZATION CAPACITY' | translate }}"
  (onClick)="openPopup()"></dx-button>
<ms-form-capacita-vaporizzazione-popup [(visible)]="popupVisible" [id]="id" (afterClosed)="afterClosed($event)">
</ms-form-capacita-vaporizzazione-popup>
<ms-form-csv-popup [(visible)]="filePopupVisible" [text]="'CSV VAPORIZATION CAPACITY' | translate"
  (afterClosed)="afterFileClosed($event)"></ms-form-csv-popup>