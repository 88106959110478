import { Ritegno, RitegnoService } from './../ritegno.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { LoadingService } from 'app/common/loading.service';
import { ConfigService } from 'app/common/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from 'app/common/utils.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ms-form-ritegno',
  templateUrl: './form-ritegno.component.html',
  styleUrls: ['./form-ritegno.component.scss']
})
export class FormRitegnoComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  checkValve: Ritegno;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isDescriptionValid: boolean;
  id: any;
  photo: any;
  lineaTypes: string[];
  disegnoTypes: string[];
  omologazioneTypes: string[];

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private ritegnoService: RitegnoService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isDescriptionValid = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('CHECK VALVES');
    this.lineaTypes = this.configService.getRitegnoLineaTypes();
    this.disegnoTypes = this.configService.getDisegnoTypes();
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.disposeBag.add(this.getCheckValve().subscribe(checkValve => {
      if (!checkValve.descrizione) { checkValve.descrizione = '{}'; }
      this.checkValve = checkValve;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.checkValve); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.ritegnoService.create(this.checkValve, this.photo) : this.ritegnoService.update(this.id, this.checkValve, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['check_valves']);
  }

  private getCheckValve(): Observable<Ritegno> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT CHECK VALVE';
        return this.ritegnoService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD CHECK VALVE';
        return of(new Ritegno());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) { return false; }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isDescriptionValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
