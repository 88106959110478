<dx-popup [maxWidth]="600" [maxHeight]="380" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="interpolationData" (onFieldDataChanged)="onFieldDataChanged()"
      (onContentReady)="onContentReady()">
      <dxi-item dataField="pressure" editorType="dxNumberBox" [label]="{ text: 'PRESSURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG PRESSURE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="temperature" editorType="dxNumberBox" [label]="{ text: 'TEMPERATURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG ONE TEMPERATURE' | translate"></dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>