<dx-popup [maxWidth]="600" [maxHeight]="540" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="dehydratingCapacity" (onFieldDataChanged)="onFieldDataChanged()"
      (onContentReady)="onContentReady()">
      <dxi-item dataField="refrigerante_id" editorType="dxSelectBox" [editorOptions]="refrigerantEditorOptions$ | async"
        [label]="{text: 'REFRIGERANT' | translate}">
        <dxi-validation-rule type="required" [message]="'MSG REFRIGERANT' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
        [label]="{ text: 'TYPE' | translate }" [colSpan]="1">
        <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="temperatura" editorType="dxNumberBox" [label]="{ text: 'TEMPERATURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG TEMPERATURE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="valore" editorType="dxNumberBox" [label]="{ text: 'VALUE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG VALUE' | translate"></dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>