import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { CapacitaFiltro, CapacitaFiltroService } from '../capacita-filtro.service';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';

@Component({
  selector: 'ms-grid-capacita-filtro',
  templateUrl: './grid-capacita-filtro.component.html',
  styleUrls: ['./grid-capacita-filtro.component.scss']
})
export class GridCapacitaFiltroComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  @Input() filterId: number;
  disposeBag = new DisposeBag();
  filterCapacities$: Observable<CapacitaFiltro[]>;
  id: any;
  popupVisible: boolean;
  filePopupVisible: boolean;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private capacitaFiltroService: CapacitaFiltroService
  ) {
    super(configService, dxService, loadingService);
    this.popupVisible = false;
    this.filePopupVisible = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.openPopup(event.row.data.id);
  }

  deleteButtonClick(event: any) {
    this.handleRequest(this.deleteWithConfirm(this.capacitaFiltroService.delete(event.row.data.id)));
  }

  afterClosed(event: CapacitaFiltro) {
    if (!!event) {
      event.filtro_id = this.filterId;
      const observable$ = this.isNew() ? this.capacitaFiltroService.insert(event) : this.capacitaFiltroService.put(event, this.id);
      this.handleRequest(observable$);
    }
  }

  afterFileClosed(event: File) {
    if (!!event) {
      this.handleRequest(this.withConfirm(this.capacitaFiltroService.importFromCsv(this.filterId, event), 'IMPORT CSV ALERT MSG'));
    }
  }

  openPopup(id?: string) {
    this.id = id;
    this.popupVisible = true;
  }

  openFilePopup() {
    this.filePopupVisible = true;
  }

  private loadData() {
    this.filterCapacities$ = this.capacitaFiltroService.getByFiltroId(this.filterId);
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(observable$.subscribe(() => this.loadData()));
  }

  private isNew(): boolean {
    return !this.id;
  }
}
