<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="material" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()">
        <dxi-item *ngIf="!!material" [label]="{ text: 'NAME' | translate }">
          <div *dxTemplate>
            <ms-text-box-multilanguage [(model)]="material.nome" [(isValid)]="isNameValid" [isRequired]="true"
              [errorMessage]="'MSG NAME' | translate"
              (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
          </div>
        </dxi-item>
        <dxi-item dataField="scabrezza" editorType="dxNumberBox" [label]="{ text: 'ROUGHNESS' | translate }">
          <dxi-validation-rule type="required" [message]="'MSG ROUGHNESS' | translate"></dxi-validation-rule>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>