import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RubinettoSicurezzaService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'rubinetto_sicurezza';
  }

  create(ballValve: RubinettoSicurezza, file: any): Observable<any> {
    const formData = this.setFormData(ballValve, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, ballValve: RubinettoSicurezza, file: any): Observable<any> {
    const formData = this.setFormData(ballValve, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(ballValve: RubinettoSicurezza, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(ballValve));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class RubinettoSicurezza {
  id: number;
  numero_catalogo: string;
  tipo: string;
  kv: number;
  diametro: number;
  area: number;
  coefficiente_perdita_pressione: number;
  ps: number;
  ts_min: number;
  ts_max: number;
  a_npt: string;
  b_npt: string;
  c: number;
  h1: number;
  h2: number;
  h3: number;
  l1: number;
  l2: number;
  l3: number;
  coppia_chiave_collegamento_min: number;
  coppia_chiave_collegamento_max: number;
  peso: number;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;
  linea_id: number;
  famiglia: any;

  constructor() {
    this.kv = null;
    this.diametro = null;
    this.area = null;
    this.coefficiente_perdita_pressione = null;
    this.ps = null;
    this.ts_min = null;
    this.ts_max = null;
    this.c = null;
    this.h1 = null;
    this.h2 = null;
    this.h3 = null;
    this.l1 = null;
    this.l2 = null;
    this.l3 = null;
    this.coppia_chiave_collegamento_min = null;
    this.coppia_chiave_collegamento_max = null;
    this.peso = null;
  }
}

export enum RubinettoSicurezzaTypes {
  CHANGEOVER = 'Changeover',
  SHUT_OFF = 'Shut Off'
}
