import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { FirstParameter, FirstParameterService } from '../first-parameter.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { Observable } from 'rxjs';
import { DxService } from 'app/common/dx.service';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-form-first-parameter-popup',
  templateUrl: './form-first-parameter-popup.component.html',
  styleUrls: ['./form-first-parameter-popup.component.scss']
})
export class FormFirstParameterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @Input() id: string;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<FirstParameter>();
  disposeBag = new DisposeBag();
  isVisible: boolean;
  firstParameter: FirstParameter;
  isFormInvalid: boolean;
  isContentReady: boolean;
  popupTitle$: Observable<string>;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;

  constructor(private dxService: DxService, private utils: UtilsService, private firstParameterService: FirstParameterService) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.onFormSubmit());
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  onHiding() {
    this.closePopup();
  }

  onShowing() {
    if (this.isNew()) {
      this.firstParameter = new FirstParameter();
    } else {
      this.disposeBag.add(this.firstParameterService.getOne(this.id).subscribe(
        firstParameter => {
          this.firstParameter = firstParameter;
          this.dxForm.instance.updateData(this.firstParameter);
        },
        () => this.closePopup()
      ));
    }
  }

  onShown() {
    this.popupTitle$ = this.isNew() ? this.utils.translate('ADD FIRST PARAMETER') : this.utils.translate('EDIT FIRST PARAMETER');
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) {
      this.isFormInvalid = !this.isFormValid();
    }
  }

  private onFormSubmit() {
    const data = Object.assign({}, this.firstParameter);
    this.closePopup(data);
  }

  private closePopup(firstParameter?: FirstParameter) {
    this.setVisible(false);
    this.afterClosed.emit(firstParameter);
    this.isFormInvalid = true;
    this.popupTitle$ = null;
    this.firstParameter = null;
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
