import { Quadro, QuadroService } from './../quadro.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ms-form-quadro',
  templateUrl: './form-quadro.component.html',
  styleUrls: ['./form-quadro.component.scss']
})
export class FormQuadroComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  electricalBoard: Quadro;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isInputsValid: boolean;
  isOutputsValid: boolean;
  isProbesValid: boolean;
  isDetailsValid: boolean;
  isDescriptionValid: boolean;
  id: any;
  mainPhoto: File;
  secondaryPhoto: File;
  applicationTypes: string[];
  powerSupplyTypes: string[];
  valveTypes: string[];

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private quadroService: QuadroService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isInputsValid = false;
    this.isOutputsValid = false;
    this.isProbesValid = false;
    this.isDetailsValid = false;
    this.isDescriptionValid = false;
  }

  ngOnInit() {
    this.applicationTypes = this.configService.getApplicazioneTypes();
    this.powerSupplyTypes = this.configService.getAlimentazioneTypes();
    this.valveTypes = this.configService.getEspansioneTypes();
    this.disposeBag.add(this.getelectricalBoard().subscribe(electricalBoard => {
      if (!electricalBoard.ingressi) { electricalBoard.ingressi = '{}'; }
      if (!electricalBoard.uscite) { electricalBoard.uscite = '{}'; }
      if (!electricalBoard.sonde) { electricalBoard.sonde = '{}'; }
      if (!electricalBoard.dettagli) { electricalBoard.dettagli = '{}'; }
      if (!electricalBoard.descrizione) { electricalBoard.descrizione = '{}'; }
      this.electricalBoard = electricalBoard;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.electricalBoard); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ? this.quadroService.create(this.electricalBoard, this.mainPhoto, this.secondaryPhoto) :
        this.quadroService.update(this.id, this.electricalBoard, this.mainPhoto, this.secondaryPhoto);
      this.handleRequest(observable$);
    }
  }

  mainFileChange(event: File[]) {
    this.mainPhoto = null;
    if (event.length > 0) { this.mainPhoto = event[0]; }
  }

  secondaryFileChange(event: File[]) {
    this.secondaryPhoto = null;
    if (event.length > 0) { this.secondaryPhoto = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['electrical_boards']);
  }

  private getelectricalBoard(): Observable<Quadro> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT ELECTRICAL BOARD';
        return this.quadroService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD ELECTRICAL BOARD';
        return of(new Quadro());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) { return false; }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isInputsValid && this.isOutputsValid && this.isProbesValid && this.isDetailsValid && this.isDescriptionValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
