import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../common/utils.service';
import {LoadingService} from '../../common/loading.service';

@Component({
  selector: 'ms-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  constructor(public loadingService: LoadingService, private utils: UtilsService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('USERS');
  }
}
