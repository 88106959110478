<dx-data-grid [dataSource]="mopdList" allowColumnReordering="true" rowAlternationEnabled="true"
  allowColumnResizing="true" showBorders="true" columnHidingEnabled="true">
  <dxo-editing mode="form" allowUpdating="true" allowDeleting="true"></dxo-editing>
  <dxi-column dataField="serie_bobina" caption="{{ 'COIL SERIES' | translate }}">
    <dxo-lookup [dataSource]="coilSeries$ | async"></dxo-lookup>
    <dxi-validation-rule type="required" [message]="'MSG SERIES' | translate"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="valore" dataType="number" caption="{{ 'VALUE' | translate }}" alignment="left">
    <dxi-validation-rule type="required" [message]="'MSG VALUE' | translate"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="tipo_corrente" caption="{{ 'CURRENT TYPE' | translate }}">
    <dxo-lookup [dataSource]="currentTypes"></dxo-lookup>
    <dxi-validation-rule type="required" [message]="'MSG CURRENT TYPE' | translate"></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
<dx-button text="{{ 'ADD MOPD' | translate }}" type="normal" (onClick)="addMOPD()"
  [elementAttr]="{ class: 'button-margin' }"></dx-button>