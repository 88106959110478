import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuadroService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'quadro';
  }

  create(electricalBoard: Quadro, mainFile: any, secondaryFile: any): Observable<any> {
    const formData = this.setFormData(electricalBoard, mainFile, secondaryFile);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, electricalBoard: Quadro, mainFile: any, secondaryFile: any): Observable<any> {
    const formData = this.setFormData(electricalBoard, mainFile, secondaryFile);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(electricalBoard: Quadro, mainFile: any, secondaryFile: any): FormData {
    electricalBoard.ingressi = JSON.stringify(electricalBoard.ingressi);
    electricalBoard.uscite = JSON.stringify(electricalBoard.uscite);
    electricalBoard.sonde = JSON.stringify(electricalBoard.sonde);
    electricalBoard.dettagli = JSON.stringify(electricalBoard.dettagli);
    electricalBoard.descrizione = JSON.stringify(electricalBoard.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(electricalBoard));
    if (!!mainFile) { formData.append('file', mainFile, 'main'); }
    if (!!secondaryFile) { formData.append('file', secondaryFile, 'secondary'); }
    return formData;
  }
}

export class Quadro {
  id: number;
  numero_catalogo: string;
  descrizione: string;
  alimentazione: string;
  tipo_alimentazione: string;
  sbrinamento: number;
  compressore: number;
  protezione_termica: string;
  ingressi: string;
  uscite: string;
  applicazione: string;
  sonde: string;
  dettagli: string;
  tipo_valvola: string;
  display: boolean;
  main_photo_id: number;
  main_photo_url: string;
  secondary_photo_id: number;
  secondary_photo_url: string;

  constructor() {
    this.sbrinamento = null;
    this.compressore = null;
  }
}

export enum ApplicazioneTypes {
  CONTROLLO_VALVOLA = 'Controllo valvola',
  UNITA_EVAPORANTE = 'Unità evaporante',
  COMPLETO_CELLA = 'Completo cella'
}

export enum AlimentazioneTypes {
  MONOFASE = 'Monofase',
  TRIFASE = 'Trifase'
}
