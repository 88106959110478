<div class="sidebar-panel gene-sidenav" fxLayout="column">
   <mat-toolbar class="sidebar-logo">
      <div class="gene-logo">
         <h1 class="title">{{ 'APP NAME' | translate }}</h1>
      </div>
   </mat-toolbar>
   <perfect-scrollbar>
      <div class="sidebar-container">
         <div class="gene-user-thumb text-center">
            <img class="img-responsive img-circle" src="assets/img/placeholder_user.png" width="107" height="107"
               alt="user list image">
            <div class="gene-user-name">{{ username }}</div>
         </div>
         <div class="sidenav-scrollbar-container" fxLayout="column">
            <ng-container>
               <mat-nav-list fxLayout="column" menuToggleDirective class="navigation">
                  <mat-list-item menuToggleLink *ngFor="let menuitem of mItems">
                     <a menuToggle class="gene-relative" mat-ripple [routerLink]="['/', menuitem.state]"
                        routerLinkActive="active-link" *ngIf="menuitem.type === 'link'">
                        <mat-icon>{{ menuitem.icon }}</mat-icon>
                        <span>{{ menuitem.name | translate }}</span>
                     </a>
                     <span></span>
                     <a menuToggle class="gene-relative" mat-ripple href="javascript:void(0)"
                        *ngIf="menuitem.type === 'sub'">
                        <mat-icon>{{ menuitem.icon }}</mat-icon>
                        <span>{{ menuitem.name | translate }}</span>
                        <span fxFlex></span>
                        <mat-icon class="menu-caret">chevron_right</mat-icon>
                     </a>
                     <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
                        <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
                           <a [routerLink]="['/', childitem.state]" routerLinkActive="active-link"
                              class="gene-relative" mat-ripple>
                              <span>{{ childitem.name | translate }}</span>
                           </a>
                        </mat-list-item>
                     </mat-nav-list>
                  </mat-list-item>
               </mat-nav-list>
            </ng-container>
         </div>
      </div>
   </perfect-scrollbar>
</div>