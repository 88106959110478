<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="electricalBoard" (onFieldDataChanged)="onFieldDataChanged()"
        (onContentReady)="onContentReady()" [colCount]="4">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="protezione_termica" [label]="{ text: 'THERMAL PROTECTION' | translate }" [colSpan]="2">
        </dxi-item>
        <dxi-item dataField="applicazione" editorType="dxSelectBox" [editorOptions]="{ dataSource: applicationTypes }"
          [label]="{text: 'APPLICATION' | translate}" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG APPLICATION' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo_alimentazione" editorType="dxSelectBox"
          [editorOptions]="{ dataSource: powerSupplyTypes }" [label]="{text: 'POWER SUPPLY TYPE' | translate}"
          [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="alimentazione" [label]="{ text: 'POWER SUPPLY' | translate }" [colSpan]="2">
          <dxi-validation-rule type="required" [message]="'MSG POWER SUPPLY' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo_valvola" editorType="dxSelectBox" [editorOptions]="{ dataSource: valveTypes }"
          [label]="{text: 'EXPANSION VALVE TYPE' | translate}" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="sbrinamento" editorType="dxNumberBox" [label]="{ text: 'DEFROST' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="compressore" editorType="dxNumberBox" [label]="{ text: 'COMPRESSOR' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="display" editorType="dxCheckBox" [label]="{ visible: false }" cssClass="check-box-item"
          [editorOptions]="{ text: 'ON BOARD DISPLAY' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item *ngIf="!!electricalBoard" itemType="group" [colSpan]="4" [colCount]="2">
          <dxi-item [label]="{ text: 'INPUTS' | translate }" [colSpan]="1">
            <div *dxTemplate>
              <ms-text-area-multilanguage [(model)]="electricalBoard.ingressi" [(isValid)]="isInputsValid"
                [isRequired]="false" [height]="100" (isValidChange)="onFieldDataChanged()"></ms-text-area-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'OUTPUTS' | translate }" [colSpan]="1">
            <div *dxTemplate>
              <ms-text-area-multilanguage [(model)]="electricalBoard.uscite" [(isValid)]="isOutputsValid"
                [isRequired]="false" [height]="100" (isValidChange)="onFieldDataChanged()"></ms-text-area-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'PROBES' | translate }" [colSpan]="1">
            <div *dxTemplate>
              <ms-text-area-multilanguage [(model)]="electricalBoard.sonde" [(isValid)]="isProbesValid"
                [isRequired]="false" [height]="100" (isValidChange)="onFieldDataChanged()"></ms-text-area-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'DETAILS' | translate }" [colSpan]="1">
            <div *dxTemplate>
              <ms-text-area-multilanguage [(model)]="electricalBoard.dettagli" [(isValid)]="isDetailsValid"
                [isRequired]="false" [height]="100" (isValidChange)="onFieldDataChanged()"></ms-text-area-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'DESCRIPTION' | translate }" [colSpan]="2">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="electricalBoard.descrizione" [(isValid)]="isDescriptionValid"
                [isRequired]="false" (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'MAIN IMAGE' | translate }" [colSpan]="2">
            <div *dxTemplate>
              <img *ngIf="!!electricalBoard && !!electricalBoard.main_photo_url" class="previewImg"
                [attr.src]="electricalBoard.main_photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                  accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="mainFileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'SECONDARY IMAGE' | translate }" [colSpan]="4">
            <div *dxTemplate>
              <img *ngIf="!!electricalBoard.secondary_photo_url" class="previewImg"
                [attr.src]="electricalBoard.secondary_photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText="" uploadMode="useForm"
                  accept="image/png, image/jpg, image/jpeg" (valueChange)="secondaryFileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>