import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent implements OnInit {
  constructor(public loadingService: LoadingService, private utils: UtilsService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('FILTERS');
  }
}
