import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';
import { Cartuccia } from '../cartuccia/cartuccia.service';

@Injectable({
  providedIn: 'root'
})
export class FiltroService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'filtro';
  }

  create(filter: Filtro, file: any): Observable<any> {
    const formData = this.setFormData(filter, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, filter: Filtro, file: any): Observable<any> {
    const formData = this.setFormData(filter, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(filter: Filtro, file: any): FormData {
    filter.descrizione = JSON.stringify(filter.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(filter));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Filtro {
  id: number;
  numero_catalogo: string;
  famiglia: string;
  tipo: string;
  numero_cartucce: number;
  riferimento: string;
  apertura: number;
  superficie_filtrante: number;
  passaggio_superficie: number;
  cubatura_inch: number;
  cubatura_cm: number;
  attacco_sae: string;
  attacco_ods_inch: string;
  attacco_ods_mm: number;
  attacco_odm_inch: string;
  attacco_odm_mm: number;
  attacco_w_mm: number;
  kv: number;
  ps: number;
  ts_min: number;
  ts_max: number;
  ta_min: number;
  ta_max: number;
  categoria_rischio: string;
  d1: number;
  d2: number;
  h1: number;
  h2: number;
  h3: number;
  l: number;
  p: number;
  q: number;
  peso: number;
  linea_id: number;
  linea: any;
  cartucce: Cartuccia[];
  descrizione: string;
  omologazioni: string[];
  photo_id: number;
  photo_url: string;

  constructor() {
    this.numero_cartucce = null;
    this.apertura = null;
    this.superficie_filtrante = null;
    this.passaggio_superficie = null;
    this.cubatura_inch = null;
    this.cubatura_cm = null;
    this.attacco_ods_mm = null;
    this.attacco_odm_mm = null;
    this.attacco_w_mm = null;
    this.kv = null;
    this.ps = null;
    this.ts_min = null;
    this.ts_max = null;
    this.ta_min = null;
    this.ta_max = null;
    this.d1 = null;
    this.d2 = null;
    this.h1 = null;
    this.h2 = null;
    this.h3 = null;
    this.l = null;
    this.p = null;
    this.q = null;
    this.peso = null;
  }
}

export enum FiltroFamilies {
  CARTUCCIA = 'Cartuccia',
  ERMETICO = 'Ermetico'
}

export enum FiltroTypes {
  BIFLOW = 'BiFlow',
  DRIER_80_20 = 'Drier 80/20',
  DRIER_100 = 'Drier 100',
  STRAINER = 'Strainer'
}
