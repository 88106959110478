import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router) { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            console.log(localStorage.getItem('currentUser'));
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/session/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

