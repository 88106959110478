<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="core" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        [colCount]="2">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
          [label]="{ text: 'TYPE' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="superficie_filtrante_inch" editorType="dxNumberBox"
          [label]="{ text: 'BLOCK FILTERING SURFACE INCH' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="superficie_filtrante_cm" editorType="dxNumberBox"
          [label]="{ text: 'BLOCK FILTERING SURFACE CM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="cubatura_inch" editorType="dxNumberBox"
          [label]="{ text: 'NOMINAL VOLUME INCH' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="cubatura_cm" editorType="dxNumberBox" [label]="{ text: 'NOMINAL VOLUME CM' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="d1" editorType="dxNumberBox" [label]="{ text: 'D1' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="d2" editorType="dxNumberBox" [label]="{ text: 'D2' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="h" editorType="dxNumberBox" [label]="{ text: 'H' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="peso" editorType="dxNumberBox" [label]="{ text: 'WEIGHT' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item *ngIf="!!core" [colSpan]="2">
          <div *dxTemplate>
            <img *ngIf="!!core.photo_url" class="previewImg" [attr.src]="core.photo_url | blobImageUrl | async" />
            <div class="fileuploader-container">
              <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>