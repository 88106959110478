import { Indicatore, IndicatoreService } from './../indicatore.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { DxFormComponent } from 'devextreme-angular';
import { AppApiService } from 'app/common/app-api.service';
import { DxService } from 'app/common/dx.service';

@Component({
  selector: 'ms-form-indicatore',
  templateUrl: './form-indicatore.component.html',
  styleUrls: ['./form-indicatore.component.scss']
})
export class FormIndicatoreComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  indicator: Indicatore;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  isDescriptionValid: boolean;
  id: any;
  photo: any;
  lineaTypes: string[];
  omologazioneTypes: string[];
  familyEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private indicatoreService: IndicatoreService,
    private dxService: DxService,
    private appApiService: AppApiService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.isDescriptionValid = false;
  }

  ngOnInit() {
    this.familyEditorOptions$ = this.dxService.dxiItemEditorOptions(this.appApiService.getFamilies(), 'value', 'this');
    this.lineaTypes = this.configService.getIndicatoreLineaTypes();
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.disposeBag.add(this.getIndicator().subscribe(indicator => {
      if (!indicator.descrizione) { indicator.descrizione = '{}'; }
      this.indicator = indicator;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.indicator); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.indicatoreService.create(this.indicator, this.photo) : this.indicatoreService.update(this.id, this.indicator, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['indicators']);
  }

  private getIndicator(): Observable<Indicatore> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT INDICATOR';
        return this.indicatoreService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD INDICATOR';
        return of(new Indicatore());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    if (!this.isContentReady) { return false; }
    const result = this.dxForm.instance.validate();
    return result.isValid && this.isDescriptionValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}
