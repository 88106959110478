import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapacitaScaricoService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'capacita_scarico';
  }

  getBySicurezzaId(safetyValveId: number): Observable<CapacitaScarico[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, 'sicurezza', safetyValveId.toString()));
  }

  importFromCsv(safetyValveId: number, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.requestService.multipartRequestWithFormData(
      'PUT',
      this.apiService.createUrlFromBaseUrl(this.target, 'sicurezza', safetyValveId.toString(), 'csv'),
      formData,
      true
    );
  }
}

export class CapacitaScarico {
  id: number;
  refrigerante_id: number;
  sicurezza_id: number;
  portata_massa: number;
  portata_volume: number;
  pressione: number;
}
