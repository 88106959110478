import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import 'rxjs/add/operator/map'
import { environment } from '../../environments/environment';
import { switchMap, tap } from 'rxjs/operators';

const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

export interface CurrentUser {
  id: number;
  name: string;
  token: string;
  accessToken: string;
}

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<boolean> {
    return this.apiLogin(email, password).pipe(
      switchMap(res => {
        const token = res.token;
        return !token ? of(null) : this.apiMe(res);
      })
    ).map(res => {
      console.log(`token : ` + res.token);
      console.log(res);
      localStorage.setItem('currentUser', JSON.stringify(res));
      return !!res;
    });
  }

  apiLogin(email: string, password: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/auth/login', JSON.stringify({ email: email, password: password }), options);
  }

  apiRefresh(): Observable<any> {
    const user: any = this.getUser();
    const refreshToken = user.refreshToken;
    return this.http.post(environment.baseUrl + '/auth/refresh', JSON.stringify({ refreshToken: refreshToken }), options)
      .map((response: HttpResponse<any>) => response).pipe(tap(res => {
        const currentUser = this.getUser();
        const newCurrentUser = Object.assign(currentUser, res);
        localStorage.setItem('currentUser', JSON.stringify(newCurrentUser));
      }));
  }

  apiMe(res: any): Observable<any> {
    const { token } = res;
    const tmpOptions = { headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }) };
    return this.http.get(environment.baseUrl + '/user/me', tmpOptions)
      .map((response: HttpResponse<any>) => Object.assign(res, response));
  }

  logout(): void {
    localStorage.removeItem('currentUser');
  }

  getUser(): CurrentUser {
    const user = localStorage.getItem('currentUser');
    return !!user ? JSON.parse(user) : null;
  }

  getRole(): string {
    const currentUser = this.getUser() as any;
    return currentUser.role || 'user';
  }

  isAdmin(): boolean {
    return this.getRole() === 'admin';
  }
}
