import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartucciaService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'cartuccia';
  }

  create(core: Cartuccia, file: any): Observable<any> {
    const formData = this.setFormData(core, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, core: Cartuccia, file: any): Observable<any> {
    const formData = this.setFormData(core, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(core: Cartuccia, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(core));
    if (!!file) { formData.append('file', file); }
    return formData;
  }
}

export class Cartuccia {
  id: string;
  numero_catalogo: string;
  tipo: string;
  superficie_filtrante_inch: number;
  superficie_filtrante_cm: number;
  cubatura_inch: number;
  cubatura_cm: number;
  d1: number;
  d2: number;
  h: number;
  peso: number;
  photo_id: number;
  photo_url: string;

  constructor() {
    this.superficie_filtrante_inch = null;
    this.superficie_filtrante_cm = null;
    this.cubatura_inch = null;
    this.cubatura_cm = null;
    this.d1 = null;
    this.d2 = null;
    this.h = null;
    this.peso = null;
  }
}
