import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';

@Injectable({
  providedIn: 'root'
})
export class ResaFrigoriferaService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'resa_frigorifera';
  }

  getByRefrigerantId(refrigerantId: number): Observable<ResaFrigorifera> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, 'refrigerante', refrigerantId.toString()));
  }

  getFilteredByRefrigerantId(refrigerantId: number): Observable<ResaFrigorifera> {
    return this.getList().map(refrigerationCapacities => {
      refrigerationCapacities = refrigerationCapacities.filter(refrigerationCapacity =>
        refrigerationCapacity.refrigerante_id === refrigerantId
      );
      return refrigerationCapacities[0];
    })
  }
}

export class ResaFrigorifera {
  id: number;
  refrigerante_id: number;
  linea_liquido: number;
  linea_aspirazione: number;
  linea_gas_caldo: number;
}
