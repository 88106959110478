import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';

@Injectable({
  providedIn: 'root'
})
export class SecondParameterService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'second_parameter';
  }
}

export class SecondParameter {
  id: number;
  caduta_pressione: number;
  nome_fattore: string;
  fattore: number;
}
