import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Menu, MenuItems } from 'app/core/menu/menu-items/menu-items';
import { CoreService } from 'app/core/core.service';
import { AuthenticationService } from 'app/authentication/authentication.service';

@Component({
   selector: 'ms-side-bar',
   templateUrl: './side-bar.component.html',
   styleUrls: ['./side-bar.component.scss']
})

export class SideBarComponent implements OnInit {
   username: string;
   role: string;
   mItems: Menu[];

   constructor(
      public translate: TranslateService,
      public coreService: CoreService,
      public menuItems: MenuItems,
      private authenticationService: AuthenticationService
   ) { }

   ngOnInit() {
      const user = this.authenticationService.getUser();
      if (!!user) {
         this.username = user['email'];
         this.role = user['role'];
         this.mItems = this.menuItems.getByRole(this.role);
      }
   }
}
