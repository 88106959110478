<dx-data-grid [dataSource]="refrigerants$ | async" allowColumnReordering="true" rowAlternationEnabled="true"
  allowColumnResizing="true" showBorders="true" columnHidingEnabled="true"
  (onToolbarPreparing)="onToolbarPreparing($event)" [masterDetail]="{ enabled: true, template: 'detail' }">
  <dxo-export enabled="true" fileName="SelectorRefrigerants"></dxo-export>
  <dxo-filter-row visible="true"></dxo-filter-row>
  <dxo-header-filter visible="true"></dxo-header-filter>
  <dxo-paging pageSize="10"></dxo-paging>
  <dxo-pager showPageSizeSelector="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
  <dxo-search-panel visible="true" highlightCaseSensitive="true"></dxo-search-panel>
  <dxo-group-panel visible="true"></dxo-group-panel>
  <dxo-grouping autoExpandAll="false"></dxo-grouping>
  <dxi-column type="buttons" [width]="buttonWidth">
    <dxi-button icon="trash" [onClick]="deleteButtonClick" hint="{{ 'DELETE REFRIGERANT' | translate }}"></dxi-button>
    <dxi-button icon="edit" [onClick]="editButtonClick" hint="{{ 'EDIT REFRIGERANT' | translate }}"></dxi-button>
  </dxi-column>
  <dxi-column dataField="name" caption="{{ 'NAME' | translate }}"></dxi-column>
  <dxi-column dataField="famiglia_solenoide" caption="{{ 'MAIN FAMILY' | translate }}"></dxi-column>
  <dxi-column dataField="famiglia_rubinetto" caption="{{ 'SECOND FAMILY' | translate }}"></dxi-column>
  <div *dxTemplate="let refrigerant of 'detail'">
    <ms-resa-frigorifera [refrigerantId]="refrigerant.data.id"></ms-resa-frigorifera>
    <dx-tab-panel [dataSource]="sections$ | async">
      <div *dxTemplate="let tabItem of 'title'">
        <span>{{ tabItem.text }}</span>
      </div>
      <div *dxTemplate="let tabItem of 'item'">
        <ms-grid-first-parameter *ngIf="tabItem.id === 0"
          [refrigerantId]="refrigerant.data.id"></ms-grid-first-parameter>
        <ms-grid-third-parameter *ngIf="tabItem.id === 1"
          [refrigerantId]="refrigerant.data.id"></ms-grid-third-parameter>
        <ms-grid-filter-parameter *ngIf="tabItem.id === 2"
          [refrigerantId]="refrigerant.data.id"></ms-grid-filter-parameter>
        <ms-grid-capacita-frigorifera *ngIf="tabItem.id === 3"
          [refrigerantId]="refrigerant.data.id"></ms-grid-capacita-frigorifera>
        <ms-grid-interpolation-data *ngIf="tabItem.id === 4"
          [refrigerantId]="refrigerant.data.id"></ms-grid-interpolation-data>
        <ms-grid-f-parameter *ngIf="tabItem.id === 5" [refrigerantId]="refrigerant.data.id"></ms-grid-f-parameter>
        <ms-grid-capacita-vaporizzazione *ngIf="tabItem.id === 6"
          [refrigerantId]="refrigerant.data.id"></ms-grid-capacita-vaporizzazione>
      </div>
    </dx-tab-panel>
  </div>
</dx-data-grid>
<ms-form-refrigerante-popup [(visible)]="popupVisible" [id]="id" (afterClosed)="afterClosed($event)">
</ms-form-refrigerante-popup>