import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterpolationDataService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'interpolation_data';
  }

  getByRefrigerantId(refrigerantId: number): Observable<InterpolationData[]> {
    return this.requestService.get(
      this.apiService.createUrlFromBaseUrl(this.target, 'refrigerante', refrigerantId.toString())
    );
  }

  importFromCsv(refrigerantId: number, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.requestService.multipartRequestWithFormData(
      'PUT',
      this.apiService.createUrlFromBaseUrl(this.target, 'refrigerante', refrigerantId.toString(), 'csv'),
      formData,
      true
    );
  }
}

export class InterpolationData {
  id: number;
  refrigerante_id: number;
  pressure: number;
  temperature: number;
}