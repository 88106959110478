import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/do';  // debug
import 'rxjs/add/operator/catch';
import { LogService } from './log.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { InterceptorSkipHeader } from '../authentication/token-interceptor';
import { take } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private http: HttpClient, private logger: LogService) { }

  get(url: string, headers = {}): Observable<any> {
    return this.request('GET', url, headers);
  }

  put(url: string, data: any, headers = {}): Observable<any> {
    return this.request('PUT', url, data, headers);
  }

  post(url: string, data: any, headers = {}): Observable<any> {
    return this.request('POST', url, data, headers);
  }

  patch(url: string, data: any, headers = {}): Observable<any> {
    return this.request('PATCH', url, data, headers);
  }

  delete(url: string, headers = {}): Observable<any> {
    return this.request('DELETE', url, headers);
  }

  downloadFile(url: string, headers: any, type: string, fileName = '') {
    this.http.get(url, { responseType: 'arraybuffer', headers: headers }).subscribe(response =>
      this.downLoadFileFromResponse(response, type, fileName)
    );
  }

  multipartRequestWithFormData(method: string, url: string, formData: FormData, reportProgress = false): Observable<any> {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const options = { headers: headers, reportProgress: reportProgress };
    const req = new HttpRequest(method, url, formData, options);
    return this.handleResponseError(this.http.request(req));
  }

  protected handleResponseError(req: Observable<any>): Observable<any> {
    return req.catch((e: any) => throwError(this.handleErrorObservable(e)));
  }

  protected handleErrorObservable(error: HttpErrorResponse | any): Observable<any> {
    console.error(error);
    if (error instanceof HttpErrorResponse) {
      const status = error.status;
      if (status && status === 409) {
        this.logger.conflictError();
      } else if (status && status === 406) {
        this.logger.notAccetableError();
      } else if (status && status === 401) {
        this.logger.unauthorizedError();
      } else if (status && status === 400) {
        this.logger.badRequestError();
      } else if (!status || status === 0) {
        this.logger.unknownError()
      } else {
        this.logger.serverError();
      }
    } else {
      this.logger.serverError();
    }
    const message = error.error.message || error.statusText;
    return throwError(message);
  }

  private request(method: string, url: string, data: {}, headers = {}): Observable<any> {
    const options = { headers: headers };
    let req = undefined;
    switch (method) {
      case 'GET': {
        req = this.http.get(url, options);
        break;
      }
      case 'PUT': {
        req = this.http.put(url, data, options);
        break;
      }
      case 'POST': {
        req = this.http.post(url, data, options);
        break;
      }
      case 'PATCH': {
        req = this.http.patch(url, data, options);
        break;
      }
      case 'DELETE': {
        req = this.http.delete(url, options);
        break;
      }
      default: {
        break;
      }
    }
    return this.handleResponseError(req.pipe(take(1)));
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  private downLoadFileFromResponse(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type: type });
    importedSaveAs(blob, fileName);
  }
}
