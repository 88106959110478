import { Injectable } from '@angular/core';
import { ApiService } from 'app/common/api.service';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SondaService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'sonda';
  }

  create(probe: Sonda, file: any): Observable<any> {
    const formData = this.setFormData(probe, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  update(id: string, probe: Sonda, file: any): Observable<any> {
    const formData = this.setFormData(probe, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id), formData, true);
  }

  private setFormData(probe: Sonda, file: any): FormData {
    probe.nome = JSON.stringify(probe.nome);
    probe.note = JSON.stringify(probe.note);
    probe.descrizione = JSON.stringify(probe.descrizione);
    const formData = new FormData();
    formData.append('data', JSON.stringify(probe));
    if (!!file) {
      formData.append('file', file);
    }
    return formData;
  }
}

export class Sonda {
  id: number;
  numero_catalogo: string;
  nome: string;
  tipo: string;
  segnale: string;
  range_misura: string;
  note: string;
  descrizione: string;
  photo_id: number;
  photo_url: string;
}

export enum SondaTypes {
  PRESSIONE = 'Pressione',
  TEMPERATURA = 'Temperatura'
}
