<dx-data-grid [dataSource]="adapters$ | async" allowColumnReordering="true" rowAlternationEnabled="true"
  allowColumnResizing="true" showBorders="true" columnHidingEnabled="true"
  (onToolbarPreparing)="onToolbarPreparing($event)">
  <dxo-export enabled="true" fileName="Adapters"></dxo-export>
  <dxo-filter-row visible="true"></dxo-filter-row>
  <dxo-header-filter visible="true"></dxo-header-filter>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-paging pageSize="10"></dxo-paging>
  <dxo-pager showPageSizeSelector="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
  <dxo-search-panel visible="true" highlightCaseSensitive="true"></dxo-search-panel>
  <dxo-group-panel visible="true"></dxo-group-panel>
  <dxo-grouping autoExpandAll="false"></dxo-grouping>
  <dxi-column type="buttons" [width]="buttonWidth">
    <dxi-button icon="trash" [onClick]="deleteButtonClick" hint="{{ 'DELETE ADAPTER' | translate }}"></dxi-button>
    <dxi-button icon="edit" [onClick]="editButtonClick" hint="{{ 'EDIT ADAPTER' | translate }}"></dxi-button>
  </dxi-column>
  <dxi-column dataField="photo_url" caption="{{ 'IMAGE' | translate }}" allowExporting="false" [width]="imageWidth"
    allowFiltering="false" allowSorting="false" cellTemplate="thumbnailCellTemplate"></dxi-column>
  <div *dxTemplate="let cell of 'thumbnailCellTemplate'">
    <img [attr.src]="cell.value | blobImageUrl | async" />
  </div>
  <dxi-column dataField="numero_catalogo" caption="{{ 'CATALOGUE NUMBER' | translate }}"></dxi-column>
</dx-data-grid>
<ms-form-adattatore-popup [(visible)]="popupVisible" [id]="id" (afterClosed)="afterClosed($event)">
</ms-form-adattatore-popup>