import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CapacitaDisidratante, CapacitaDisidratanteService, CapacitaDisidratanteTypes } from '../capacita-disidratante.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { DxService } from 'app/common/dx.service';
import { UtilsService } from 'app/common/utils.service';
import { RefrigeranteService } from 'app/components/refrigerante/refrigerante.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-form-capacita-disidratante-popup',
  templateUrl: './form-capacita-disidratante-popup.component.html',
  styleUrls: ['./form-capacita-disidratante-popup.component.scss']
})
export class FormCapacitaDisidratantePopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @Input() id: string;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() afterClosed = new EventEmitter<CapacitaDisidratante>();
  disposeBag = new DisposeBag();
  isVisible: boolean;
  dehydratingCapacity: CapacitaDisidratante;
  isFormInvalid: boolean;
  isContentReady: boolean;
  popupTitle$: Observable<string>;
  popupSubmitOptions$: Observable<any>;
  popupCancelOptions$: Observable<any>;
  refrigerantEditorOptions$: Observable<any>;
  types: CapacitaDisidratanteTypes[];

  constructor(
    private dxService: DxService,
    private utils: UtilsService,
    private capacitaDisidratanteService: CapacitaDisidratanteService,
    private refrigeranteService: RefrigeranteService,
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
    this.types = Object.values(CapacitaDisidratanteTypes);
  }

  ngOnInit() {
    this.popupSubmitOptions$ = this.dxService.dxToolbarItemOptions('SUBMIT', () => this.onFormSubmit());
    this.popupCancelOptions$ = this.dxService.dxToolbarItemOptions('CANCEL', () => this.closePopup());
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  onHiding() {
    this.closePopup();
  }

  onShowing() {
    this.refrigerantEditorOptions$ = this.dxService.dxiItemEditorOptions(this.refrigeranteService.getList(), 'name', 'id', !this.isNew());
    if (this.isNew()) {
      this.dehydratingCapacity = new CapacitaDisidratante();
    } else {
      this.disposeBag.add(this.capacitaDisidratanteService.getOne(this.id).subscribe(
        dehydratingCapacity => {
          this.dehydratingCapacity = dehydratingCapacity;
          this.dxForm.instance.updateData(this.dehydratingCapacity);
        },
        () => this.closePopup()
      ));
    }
  }

  onShown() {
    this.popupTitle$ =
      this.isNew() ? this.utils.translate('ADD DEHYDRATING CAPACITY') : this.utils.translate('EDIT DEHYDRATING CAPACITY');
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) { this.isFormInvalid = !this.isFormValid(); }
  }

  private onFormSubmit() {
    const data = Object.assign({}, this.dehydratingCapacity);
    this.closePopup(data);
  }

  private closePopup(dehydratingCapacity?: CapacitaDisidratante) {
    this.setVisible(false);
    this.afterClosed.emit(dehydratingCapacity);
    this.isFormInvalid = true;
    this.popupTitle$ = null;
    this.dehydratingCapacity = null;
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }

  @Input()
  get visible() {
    return this.isVisible;
  }

  set visible(val: boolean) {
    this.setVisible(val);
  }

  setVisible(val: boolean) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }
}
