<dx-popup [maxWidth]="600" [maxHeight]="450" [title]="popupTitle$ | async" [closeOnOutsideClick]="true"
  [(visible)]="visible" (onHiding)="onHiding()" (onShowing)="onShowing()" (onShown)="onShown()">
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupSubmitOptions$ | async" toolbar="bottom"
    [disabled]="isFormInvalid">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" location="after" [options]="popupCancelOptions$ | async" toolbar="bottom">
  </dxi-toolbar-item>
  <dx-scroll-view>
    <dx-form [formData]="dischargeCapacity" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()">
      <dxi-item dataField="portata_massa" editorType="dxNumberBox" [label]="{ text: 'MASS FLOW RATE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG MASS FLOW RATE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="portata_volume" editorType="dxNumberBox" [label]="{ text: 'VOLUME FLOW RATE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG VOLUME FLOW RATE' | translate"></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="pressione" editorType="dxNumberBox" [label]="{ text: 'PRESSURE' | translate }">
        <dxi-validation-rule type="required" [message]="'MSG PRESSURE' | translate"></dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </dx-scroll-view>
</dx-popup>