import { ConfigService } from 'app/common/config.service';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular';

@Component({
  selector: 'ms-text-area-multilanguage',
  templateUrl: './text-area-multilanguage.component.html',
  styleUrls: ['./text-area-multilanguage.component.scss']
})
export class TextAreaMultilanguageComponent implements OnInit {
  @ViewChild(DxValidationGroupComponent, { static: true }) dxValidationGroup: DxValidationGroupComponent;
  @Output() modelChange = new EventEmitter<any>();
  @Output() isValidChange = new EventEmitter<boolean>();
  @Input() isRequired: boolean;
  @Input() errorMessage: string;
  @Input() height: number;
  languages: any[];
  multiLanguageData: any;
  isContentReady: boolean;
  isFormValid: boolean;

  constructor(private configService: ConfigService) {
    this.isContentReady = false;
    this.isFormValid = false;
  }

  ngOnInit() {
    this.languages = this.configService.getLanguages();
  }

  onInitialized() {
    this.isContentReady = true;
    this.setIsValid(Object.keys(this.multiLanguageData).length < this.languages.length ? !this.isRequired : this.validate());
  }

  onValueChanged() {
    this.setIsValid(this.validate());
  }

  validate() {
    if (!this.isContentReady) { return false; }
    if (!this.isRequired) { return true; }
    const result = this.dxValidationGroup.instance.validate();
    return result.isValid;
  }

  @Input()
  get model() {
    return this.multiLanguageData;
  }

  set model(val: string) {
    this.setModel(val);
  }

  setModel(val: string) {
    this.multiLanguageData = JSON.parse(val);
    this.modelChange.emit(this.multiLanguageData);
    this.setIsValid(this.validate());
  }

  @Input()
  get isValid() {
    return this.isFormValid;
  }

  set isValid(val: boolean) {
    this.setIsValid(val);
  }

  setIsValid(val: boolean) {
    this.isFormValid = val;
    this.isValidChange.emit(this.isFormValid);
  }
}
