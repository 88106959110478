import { AdattatoreService } from './../../adattatore/adattatore.service';
import { Attuatore, AttuatoreService } from './../attuatore.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxService } from 'app/common/dx.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { DxFormComponent } from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ms-form-attuatore',
  templateUrl: './form-attuatore.component.html',
  styleUrls: ['./form-attuatore.component.scss']
})
export class FormAttuatoreComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  actuator: Attuatore;
  cardTitle: string;
  isFormInvalid: boolean;
  isContentReady: boolean;
  id: any;
  photo: any;
  voltageTypes: number[];
  omologazioneTypes: string[];
  adapterEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private attuatoreService: AttuatoreService,
    private dxService: DxService,
    private adattatoreService: AdattatoreService
  ) {
    this.isFormInvalid = true;
    this.isContentReady = false;
  }

  ngOnInit() {
    this.voltageTypes = this.configService.getAttuatoreTensioneTypes();
    this.omologazioneTypes = this.configService.getOmologazioneTypes();
    this.adapterEditorOptions$ = this.dxService.dxiItemEditorOptions(this.adattatoreService.getList(), 'numero_catalogo', 'this');
    this.disposeBag.add(this.getActuator().subscribe(actuator => {
      this.actuator = actuator;
      if (!this.isNew()) { this.dxForm.instance.updateData(this.actuator); }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onContentReady() {
    this.isContentReady = true;
  }

  onFieldDataChanged() {
    if (this.isContentReady) { this.isFormInvalid = !this.isFormValid(); }
  }

  onFormSubmit() {
    if (this.isFormValid()) {
      const observable$ = this.isNew() ?
        this.attuatoreService.create(this.actuator, this.photo) : this.attuatoreService.update(this.id, this.actuator, this.photo);
      this.handleRequest(observable$);
    }
  }

  fileChange(event: File[]) {
    this.photo = null;
    if (event.length > 0) { this.photo = event[0]; }
  }

  goToListPage() {
    this.router.navigate(['actuators']);
  }

  private getActuator(): Observable<Attuatore> {
    return this.route.params.map(params => params['id']).pipe(tap(id => this.id = id)).switchMap((idFound, _) => {
      if (!!idFound) {
        this.cardTitle = 'EDIT ACTUATOR';
        return this.attuatoreService.getOne(idFound);
      } else {
        this.cardTitle = 'ADD ACTUATOR';
        return of(new Attuatore());
      }
    });
  }

  private handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
  }

  private isFormValid(): boolean {
    const result = this.dxForm.instance.validate();
    return result.isValid;
  }

  private isNew(): boolean {
    return !this.id;
  }
}