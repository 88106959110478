import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'app/common/loading.service';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-attuatore',
  templateUrl: './attuatore.component.html',
  styleUrls: ['./attuatore.component.scss']
})
export class AttuatoreComponent implements OnInit {
  constructor(public loadingService: LoadingService, private utils: UtilsService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('ACTUATORS');
  }
}