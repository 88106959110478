import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { AuthenticationService } from 'app/authentication/authentication.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class BaseInterceptor {
  constructor(public auth: AuthenticationService) { }

  addAuthenticationToken(request: HttpRequest<any>): any {
    if (request.headers.has(InterceptorSkipHeader)) {
      return this.skipIntercept(request);
    }
    let headers: any = {};
    if (request.method !== 'GET' && !request.headers.has('Content-Type')) {
      headers['Content-Type'] = 'application/json; charset=utf-8';
    }
    const user = this.auth.getUser();
    if (!user) {
      return this.requestWithHeader(request, headers);
    }
    headers = this.addTokensToHeaders(user, headers);
    return this.requestWithHeader(request, headers);
  }

  skipIntercept(request: HttpRequest<any>) {
    request.headers.delete(InterceptorSkipHeader);
    const user = this.auth.getUser();
    if (!user) {
      return this.requestWithHeader(request, {});
    }
    const headers = this.addTokensToHeaders(user, {});
    return this.requestWithHeader(request, headers);
  }

  addTokensToHeaders(user: any, headers: any): any {
    if (user.token) {
      headers['Authorization'] = `Bearer ${user.token}`;
    }
    if (user.accessToken) {
      headers['X-Google-AccessToken'] = user.accessToken;
    }
    return headers;
  }

  requestWithHeader(request: HttpRequest<any>, headers: any) {
    return request.clone({ setHeaders: headers });
  }
}
