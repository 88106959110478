<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="probe" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        colCount="2">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="tipo" editorType="dxSelectBox" [editorOptions]="{ dataSource: types }"
          [label]="{ text: 'TYPE' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG TYPE' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="segnale" [label]="{ text: 'SIGNAL' | translate }" colSpan="1">
          <dxi-validation-rule type="required" [message]="'MSG SIGNAL' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="range_misura" [label]="{ text: 'RANGE' | translate }" colSpan="1"></dxi-item>
        <dxi-item *ngIf="!!probe" itemType="group" colSpan="2" colCount="2">
          <dxi-item [label]="{ text: 'NAME' | translate }" colSpan="1">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="probe.nome" [(isValid)]="isNameValid" [isRequired]="true"
                [errorMessage]="'MSG NAME' | translate"
                (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'NOTES' | translate }" colSpan="1">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="probe.note" [(isValid)]="isNotesValid" [isRequired]="false"
                (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item [label]="{ text: 'DESCRIPTION' | translate }" colSpan="2">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="probe.descrizione" [(isValid)]="isDescriptionValid"
                [isRequired]="false" (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item colSpan="2">
            <div *dxTemplate>
              <img *ngIf="!!probe.photo_url" class="previewImg" [attr.src]="probe.photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                  accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>