import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/authentication/authentication.service';
import { LoadingService } from 'app/common/loading.service';

@Component({
   selector: 'ms-login-session',
   templateUrl: './login-component.html',
   styleUrls: ['./login-component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
   loading = false;
   disposeBag = new DisposeBag();
   isErrorShow = false;
   email: string;
   password: string;
   returnUrl: string;

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      public authService: AuthenticationService,
      private loadingService: LoadingService
   ) { }

   ngOnInit() {
      this.authService.logout();
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      this.disposeBag.add(this.loadingService.loading$.subscribe(res => { this.loading = res; }));
   }

   ngOnDestroy(): void {
      this.disposeBag.dispose();
   }

   // when email and password is correct, user logged in.
   login() {
      this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.authService.login(this.email, this.password)).subscribe(
         () => {
            if (!!this.returnUrl && this.returnUrl !== '/') {
               // login successful so redirect to return url
               this.router.navigateByUrl(this.returnUrl);
               return;
            }
            this.router.navigate(['/users']);
         },
         () => this.isErrorShow = true
      ));
   }
}
