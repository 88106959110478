import { DisposeBag } from 'app/classes/dispose-bag.class';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { Materiale, MaterialeService } from './../materiale.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-grid-materiale',
  templateUrl: './grid-materiale.component.html',
  styleUrls: ['./grid-materiale.component.scss']
})
export class GridMaterialeComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  materials$: Observable<Materiale[]>;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private router: Router,
    private materialeService: MaterialeService,
    private utilsService: UtilsService
  ) {
    super(configService, dxService, loadingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.router.navigate(['materials', event.row.data.id]);
  }

  deleteButtonClick(event: any) {
    this.disposeBag.add(this.deleteWithConfirm(this.materialeService.delete(event.row.data.id)).subscribe(() => this.loadData()));
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.router.navigate(['materials/add'])
      }
    });
  }

  private loadData() {
    this.materials$ = this.loadingService.apiRequest$WithLoading(this.materialeService.getList().pipe(
      map((materials: Materiale[]) => materials.map((material: Materiale) => {
        material.nome = this.utilsService.translateMultiLanguageField(material.nome);
        return material;
      })
      )
    ));
  }
}
