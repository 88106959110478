import { FormQuadroComponent } from './components/quadro/form-quadro/form-quadro.component';
import { QuadroComponent } from './components/quadro/quadro.component';
import { FormEspansioneComponent } from './components/espansione/form-espansione/form-espansione.component';
import { EspansioneComponent } from './components/espansione/espansione.component';
import { FormAttuatoreComponent } from './components/attuatore/form-attuatore/form-attuatore.component';
import { AttuatoreComponent } from './components/attuatore/attuatore.component';
import { AdattatoreComponent } from './components/adattatore/adattatore.component';
import { FormRubinettoComponent } from './components/rubinetto/form-rubinetto/form-rubinetto.component';
import { RubinettoComponent } from './components/rubinetto/rubinetto.component';
import { FormIndicatoreComponent } from './components/indicatore/form-indicatore/form-indicatore.component';
import { IndicatoreComponent } from './components/indicatore/indicatore.component';
import { RegoloComponent } from './components/regolo/regolo.component';
import { FormConnettoreComponent } from './components/connettore/form-connettore/form-connettore.component';
import { FormBobinaComponent } from './components/bobina/form-bobina/form-bobina.component';
import { FormRitegnoComponent } from './components/ritegno/form-ritegno/form-ritegno.component';
import { FormSolenoideComponent } from './components/solenoide/form-solenoide/form-solenoide.component';
import { RefrigeranteComponent } from './components/refrigerante/refrigerante.component';
import { SolenoideComponent } from './components/solenoide/solenoide.component';
import { RitegnoComponent } from './components/ritegno/ritegno.component';
import { BobinaComponent } from './components/bobina/bobina.component';
import { ConnettoreComponent } from './components/connettore/connettore.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { UserComponent } from './components/user/user.component';
import { FormUserComponent } from './components/user/form-user/form-user.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './authentication/auth-guard.service';
import { SecondParameterComponent } from './components/second-parameter/second-parameter.component';
import { FormRefrigeranteRegoloComponent } from './components/regolo/form-refrigerante-regolo/form-refrigerante-regolo.component';
import { SondaComponent } from './components/sonda/sonda.component';
import { FormSondaComponent } from './components/sonda/form-sonda/form-sonda.component';
import { FiltroComponent } from './components/filtro/filtro.component';
import { FormFiltroComponent } from './components/filtro/form-filtro/form-filtro.component';
import { CartucciaComponent } from './components/cartuccia/cartuccia.component';
import { FormCartucciaComponent } from './components/cartuccia/form-cartuccia/form-cartuccia.component';
import { SicurezzaComponent } from './components/sicurezza/sicurezza.component';
import { FormSicurezzaComponent } from './components/sicurezza/form-sicurezza/form-sicurezza.component';
import { ConnessioneComponent } from './components/connessione/connessione.component';
import { RubinettoSicurezzaComponent } from './components/rubinetto-sicurezza/rubinetto-sicurezza.component';
import { FormRubinettoSicurezzaComponent } from './components/rubinetto-sicurezza/form-rubinetto-sicurezza/form-rubinetto-sicurezza.component';
import { MaterialeComponent } from './components/materiale/materiale.component';
import { FormMaterialeComponent } from './components/materiale/form-materiale/form-materiale.component';
import { FormConnessioneComponent } from './components/connessione/form-connessione/form-connessione.component';

const appRoutes: Routes = [
   { path: 'session/login', component: LoginComponent }, {
      path: '',
      component: MainComponent,
      canActivate: [AuthGuardService],
      children: [
         { path: 'users', component: UserComponent },
         { path: 'users/add', component: FormUserComponent },
         { path: 'users/:id', component: FormUserComponent },
         { path: 'solenoids', component: SolenoideComponent },
         { path: 'solenoids/add', component: FormSolenoideComponent },
         { path: 'solenoids/:id', component: FormSolenoideComponent },
         { path: 'check_valves', component: RitegnoComponent },
         { path: 'check_valves/add', component: FormRitegnoComponent },
         { path: 'check_valves/:id', component: FormRitegnoComponent },
         { path: 'indicators', component: IndicatoreComponent },
         { path: 'indicators/add', component: FormIndicatoreComponent },
         { path: 'indicators/:id', component: FormIndicatoreComponent },
         { path: 'ball_valves', component: RubinettoComponent },
         { path: 'ball_valves/add', component: FormRubinettoComponent },
         { path: 'ball_valves/:id', component: FormRubinettoComponent },
         { path: 'expansion_valves', component: EspansioneComponent },
         { path: 'expansion_valves/add', component: FormEspansioneComponent },
         { path: 'expansion_valves/:id', component: FormEspansioneComponent },
         { path: 'filters', component: FiltroComponent },
         { path: 'filters/add', component: FormFiltroComponent },
         { path: 'filters/:id', component: FormFiltroComponent },
         { path: 'safety_valves', component: SicurezzaComponent },
         { path: 'safety_valves/add', component: FormSicurezzaComponent },
         { path: 'safety_valves/:id', component: FormSicurezzaComponent },
         { path: 'electrical_boards', component: QuadroComponent },
         { path: 'electrical_boards/add', component: FormQuadroComponent },
         { path: 'electrical_boards/:id', component: FormQuadroComponent },
         { path: 'coils', component: BobinaComponent },
         { path: 'coils/add', component: FormBobinaComponent },
         { path: 'coils/:id', component: FormBobinaComponent },
         { path: 'actuators', component: AttuatoreComponent },
         { path: 'actuators/add', component: FormAttuatoreComponent },
         { path: 'actuators/:id', component: FormAttuatoreComponent },
         { path: 'cores', component: CartucciaComponent },
         { path: 'cores/add', component: FormCartucciaComponent },
         { path: 'cores/:id', component: FormCartucciaComponent },
         { path: 'safety_valve_accessories', component: RubinettoSicurezzaComponent },
         { path: 'safety_valve_accessories/add', component: FormRubinettoSicurezzaComponent },
         { path: 'safety_valve_accessories/:id', component: FormRubinettoSicurezzaComponent },
         { path: 'connectors', component: ConnettoreComponent },
         { path: 'connectors/add', component: FormConnettoreComponent },
         { path: 'connectors/:id', component: FormConnettoreComponent },
         { path: 'probes', component: SondaComponent },
         { path: 'probes/add', component: FormSondaComponent },
         { path: 'probes/:id', component: FormSondaComponent },
         { path: 'connections', component: ConnessioneComponent },
         { path: 'connections/add', component: FormConnessioneComponent },
         { path: 'connections/:id', component: FormConnessioneComponent },
         { path: 'materials', component: MaterialeComponent },
         { path: 'materials/add', component: FormMaterialeComponent },
         { path: 'materials/:id', component: FormMaterialeComponent },
         { path: 'adapters', component: AdattatoreComponent },
         { path: 'refrigerants', component: RefrigeranteComponent },
         { path: 'second_parameters', component: SecondParameterComponent },
         { path: 'ruler_refrigerants', component: RegoloComponent },
         { path: 'ruler_refrigerants/add', component: FormRefrigeranteRegoloComponent },
         { path: 'ruler_refrigerants/:id', component: FormRefrigeranteRegoloComponent }
      ]
   }
];

@NgModule({
   imports: [RouterModule.forRoot(appRoutes)],
   exports: [RouterModule],
   providers: []
})
export class RoutingModule { }
