<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div>
        <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <form (submit)="onFormSubmit()">
      <dx-form [formData]="indicator" (onFieldDataChanged)="onFieldDataChanged()" (onContentReady)="onContentReady()"
        [colCount]="2">
        <dxi-item dataField="numero_catalogo" [label]="{ text: 'CATALOGUE NUMBER' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG CATALOGUE NUMBER' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="linea" editorType="dxSelectBox" [editorOptions]="{ dataSource: lineaTypes }"
        [label]="{text: 'LINE' | translate}" [colSpan]="1">
        <dxi-validation-rule type="required" [message]="'MSG LINE' | translate"></dxi-validation-rule>
      </dxi-item>
        <dxi-item dataField="attacco_sae" [label]="{ text: 'SAE CONNECTION INCH' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="attacco_ods_inch" [label]="{ text: 'ODS CONNECTION INCH' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="attacco_ods_mm" editorType="dxNumberBox"
          [label]="{ text: 'ODS CONNECTION MM' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="attacco_w_mm" editorType="dxNumberBox" [label]="{ text: 'W CONNECTION MM' | translate }"
          [colSpan]="1"></dxi-item>
        <dxi-item dataField="kv" editorType="dxNumberBox" [label]="{ text: 'KV' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG KV' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="ps" editorType="dxNumberBox" [label]="{ text: 'PS' | translate }" [colSpan]="1"></dxi-item>
        <dxi-item dataField="ts_min" editorType="dxNumberBox" [label]="{ text: 'TS MIN' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="ts_max" editorType="dxNumberBox" [label]="{ text: 'TS MAX' | translate }" [colSpan]="1">
        </dxi-item>
        <dxi-item dataField="omologazioni" editorType="dxTagBox" [editorOptions]="{ dataSource: omologazioneTypes }"
          [label]="{ text: 'HOMOLOGATIONS' | translate }" [colSpan]="1"></dxi-item>
          <dxi-item dataField="famiglia" editorType="dxTagBox" [editorOptions]="familyEditorOptions$ | async"
          [label]="{ text: 'FAMILY' | translate }" [colSpan]="1">
          <dxi-validation-rule type="required" [message]="'MSG FAMILIES' | translate"></dxi-validation-rule>
        </dxi-item>
        <dxi-item *ngIf="!!indicator" itemType="group" [colSpan]="2">
          <dxi-item [label]="{ text: 'DESCRIPTION' | translate }">
            <div *dxTemplate>
              <ms-text-box-multilanguage [(model)]="indicator.descrizione" [(isValid)]="isDescriptionValid"
                [isRequired]="false" (isValidChange)="onFieldDataChanged()"></ms-text-box-multilanguage>
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <img *ngIf="!!indicator.photo_url" class="previewImg"
                [attr.src]="indicator.photo_url | blobImageUrl | async" />
              <div class="fileuploader-container">
                <dx-file-uploader selectButtonText="{{ 'SELECT IMAGE' | translate }}" labelText=""
                  accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
                </dx-file-uploader>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="pad-wrap">
        <mat-card-actions>
          <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
            [disabled]="isFormInvalid"></dx-button>
          <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()"></dx-button>
        </mat-card-actions>
      </div>
    </form>
  </mat-card>
</div>