import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sonda, SondaService } from '../sonda.service';
import { Router } from '@angular/router';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { ConfigService } from 'app/common/config.service';
import { DxService } from 'app/common/dx.service';
import { LoadingService } from 'app/common/loading.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-grid-sonda',
  templateUrl: './grid-sonda.component.html',
  styleUrls: ['./grid-sonda.component.scss']
})
export class GridSondaComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dxDataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  probes$: Observable<Sonda[]>;

  constructor(
    public configService: ConfigService,
    public dxService: DxService,
    public loadingService: LoadingService,
    private router: Router,
    private sondaService: SondaService
  ) {
    super(configService, dxService, loadingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.deleteButtonClick = this.deleteButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  editButtonClick(event: any) {
    this.router.navigate(['probes', event.row.data.id]);
  }

  deleteButtonClick(event: any) {
    this.disposeBag.add(this.deleteWithConfirm(this.sondaService.delete(event.row.data.id)).subscribe(() => this.loadData()));
  }

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.router.navigate(['probes/add'])
      }
    });
  }

  private loadData() {
    this.probes$ = this.loadingService.apiRequest$WithLoading(this.sondaService.getList());
  }
}
